import { httpHandler } from '../httpHandler'

export const getLink = planCode =>
  httpHandler.get({
    url: `/self-service/cart/seller-link`,
    params: { planCode }
  })

export const getPermanentLink = code =>
  httpHandler.get({ url: `/self-service/seller/permanent-link` })
