import React, { memo } from 'react'
import { 
  Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import * as Styled from './style'

const TabPanel = ({ children, value, index, ...other }) => (
  <Styled.Wrapper
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box p={3} style={{width: '100%', display: 'flex', overflowY: 'hidden', overflowX: 'auto'}}>
        {children}
      </Box>
    )}
  </Styled.Wrapper>
)
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default memo(TabPanel);