export function userAction(data) {
  return {
    type: 'USER_DATA',
    data
  }
}

export function logOutAction() {
  return {
    type: 'USER_LOGOUT'
  }
}