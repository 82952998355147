import styled from 'styled-components'
import { flexBox } from '../../shared/styles/mixins'
import media from 'styled-media-query';

export const Wrapper = styled.section`
  ${flexBox('column', 'flex-start', 'flex-start')};
  width: 100%;
  padding-top: 10px;
  overflow-y: auto;

  ${media.lessThan('767px')`
    width: 90%;
    margin: 0 5%;
    .MuiBox-root{
      width: 100%;
    }
  `}
`
