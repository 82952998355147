import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import ContrastedContainer from '../ContrastedContainer';
import DataField from '../DataField';
import * as Styled from './styled';

const CnpjInfos = ({ openingDate, businessName, phoneNumber }) => (
  (businessName || openingDate) && (
    <ContrastedContainer>
      <Styled.Content container spacing={1}>
        <Grid item xs={12}>
          {businessName && <DataField textStyle={{ marginBottom: 10 }} label="Razão Social" text={businessName} />}
          {openingDate && <DataField textStyle={{ marginBottom: 10 }} label="Data de abertura" text={openingDate ? new Date(openingDate).toLocaleDateString('pt-Br') : 'Dado indisponível no momento'} />}
          {phoneNumber && <DataField textStyle={{ marginBottom: 10 }} label="Telefone" text={phoneNumber} />}
        </Grid>
      </Styled.Content>
    </ContrastedContainer>
  )
);

CnpjInfos.propTypes = {
  openingDate: PropTypes.string,
  businessName: PropTypes.string,
};

CnpjInfos.defaultProps = {
  businessName: '',
  openingDate: '',
  phoneNumber: '',
}

export default memo(CnpjInfos);
