import {combineReducers} from 'redux'
import {userReducer} from './user'
import {signupReducer} from './signup'
import {awaitingBrokersDocumentReducer} from './awaitingBrokersDocument'
import {pendingBrokersReducer} from './pendingBrokers'
  
  const rootReducer = combineReducers({
    userReducer,
    signupReducer,
    awaitingBrokersDocumentReducer,
    pendingBrokersReducer,
  })
  
  export default rootReducer