import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import {
  dispatchRouteChanged,
} from '../../../shared/services/gtmEventDispatcher'
import LogoWDental from '../../../assets/svgs/logo-wdental.svg'
import { ReactSVG } from 'react-svg'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `0 20px`
  },
  text: {
    fontSize: 18,
    margin: `10px 0`,
    textAlign: 'center',
  }
}))

const Congratulations = ({ location }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatchRouteChanged({
      pageCategory: 'vendedor',
      urlpasso: window.location.pathname
    })
    dispatch({ type: 'CLEAN_SIGNUP_DATA' })
  }, [dispatch])

  return (
    <section className={classes.root}>
      <ReactSVG src={LogoWDental} style={{ marginBottom: '60px' }} />
      <span className={classes.text}>
        Nesse momento, nossa equipe está avaliando seus dados e documentos.
      </span>
      <span className={classes.text}>
        Você receberá um email com a confirmação de seu cadastro.
      </span>
      <span className={classes.text}>
        {' '}
        Bem-vindo à <strong>W.Dental!</strong>
      </span>

      <Button
        style={{ height: 40, marginTop: 30, borderRadius: 50, backgroundColor: '#121a3d', padding: `6px 50px` }}
        type='submit'
        color='primary'
        variant='contained'
        aria-label='enviar'
        name='login-button'
        component={'a'}
        href={'/login'}
        rel='noopener noreferrer'
      >
        Login
      </Button>
    </section>
  )
}

export default Congratulations
