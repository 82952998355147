import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 50,
    height: 50,
    textTransform: 'none',
  },
  icon: {
    position: 'absolute',
    right: 20,
    top: 25,
    cursor: 'pointer',
  },
  form: {
    padding: '50px 20px',
  }
}));
