import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { LABEL } from '../../shared/styles/colors';

export const Label = styled(Typography)`
  color: ${LABEL};
  &.MuiTypography-body2 {
    font-size: 12px;
  }
`;

export const Text = styled(Typography)`
  color: ${({ textcolor }) => textcolor};
  &.MuiTypography-body2 {
    font-size: 12px;
  }
  &.MuiTypography-root {
    ${({ display }) => display === 'inline' && 'margin-left: 14px;'}
    font-weight: bold;
    white-space: pre-wrap;
  }
`;