export function setItem(key, data) {
  window.localStorage.setItem(key, JSON.stringify(data));
}

export function getItem(key) {
  try {
    let data = window.localStorage.getItem(key);
    return JSON.parse(data)
  } catch(e) {
    return '';
  }
}

export function clear() {
  window.localStorage.clear();
}