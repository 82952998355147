import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { get } from 'lodash'
import Alert from '../../../components/Alert'
import { create } from '../../../shared/services/personal'
import {
  dispatchRouteChanged,
  dispatchClickEvent
} from '../../../shared/services/gtmEventDispatcher'
import UserForm from '../../../components/Forms/UserForm'
import User from '../../../models/User'
import { getCompany } from '../../../shared/services/broker'
import getParam from '../../../shared/utils/getParams'

const UserData = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [snackbarErrorOpened, setSnackbarErrorOpened] = useState(false)
  const [snackbarErrorMessage, setSnackbarErrorMessage] = useState('')
  const [homeCompany, setHomeCompany] = useState({})

  useEffect(() => {
    dispatchRouteChanged({
      pageCategory: 'vendedor',
      urlpasso: window.location.pathname
    })
    dispatch({ type: 'CLEAN_SIGNUP_DATA' })
    const name = getParam('empresa')
    if (name) {
      getCompany({ name })
        .then(setHomeCompany)
        .catch(e => {
          setSnackbarErrorMessage(
            get(
              e,
              'messages[0].text',
              'Ocorreu um erro! Favor tente mais tarde!'
            )
          )
          setSnackbarErrorOpened(true)
        })
    }
  }, [dispatch])

  const handleSubmit = (values, formikProps) => {
    dispatchClickEvent({ label: 'Próximo', type: 'submit' })
    if (values) {
      const user = new User({ ...values, homeCompanyCode: homeCompany.code })
      create(user)
        .then(({ code }) => {
          dispatch({ type: 'SIGNUP_DATA', payload: { code } })
          history.push('/cadastrar/endereco')
        })
        .catch(e => {
          setSnackbarErrorMessage(
            get(
              e,
              'messages[0].text',
              'Ocorreu um erro! Favor tente mais tarde!'
            )
          )
          setSnackbarErrorOpened(true)
        })
    }
  }

  return (
    <>
      <UserForm onSubmit={handleSubmit} />{' '}
      <Alert openState={snackbarErrorOpened} severity='error'>
        {snackbarErrorMessage}
      </Alert>
    </>
  )
}

export default UserData
