import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Button, Container, Grid, Hidden, TextField } from '@material-ui/core'
import { Formik, Form } from 'formik'
import FormField from '../../FormField'
import Select from '../../Select';
import PhoneTextField from '../../PhoneTextField'
import { handleBlurValidation, dispatchFormFieldFillEvent, dispatchFormFieldError } from '../../../shared/services/gtmEventDispatcher';
import validationSchema from './validationSchema'
import { useStyles } from '../muiStyle'
import { get } from 'lodash'
import useCpf from '../../../hooks/useCpf'
import CpfInfos from '../../CpfInfos'
import DocumentTextField from '../../DocumentTextField'

const EmployeeCreateForm = ({ onSubmit, branchesOffice }) => {

  const { consultCpf } = useCpf()
  const classes = useStyles();

  const handleChangeBranchOffice = ({ handleChange, setFieldValue }) => event => {
    const { value } = event.target;
    const selectedItem = branchesOffice.find(item => item.value === value);
    handleChange(event);
    setFieldValue('branchCode', get(selectedItem, 'value', ''));
    const dispatchGtmEvent = value ? dispatchFormFieldFillEvent : dispatchFormFieldError;
    dispatchGtmEvent({ label: 'branchOffice ' });
  };

  return (
    <Formik
      initialValues={{
        branchCode: '',
        phoneNumber: '',
        cpf: '',
        email: '',
        dateOfBirth: '',
        name: '',
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({
        values,
        handleChange,
        setFieldTouched,
        handleBlur,
        isSubmitting,
        dirty,
        isValid,
        setFieldValue,
        errors
      }) => (
          <Form className={classes.form}>
            <Container maxWidth='sm'>
              <Grid container spacing={3}>
                <Grid item xs={10}>
                  <FormField
                    fullWidth
                    name='cpf'
                    label='CPF'
                    variant='outlined'
                    onBlur={event => {
                      handleBlurValidation({
                        errors,
                        fieldName: 'cpf',
                        extraValidationKeys: ['dateOfBirth', 'name']
                      })
                      handleBlur(event)
                      setFieldTouched('cpf', true)
                    }}
                    onChange={consultCpf({ handleChange, setFieldValue })}
                    documentType='cpf'
                    component={DocumentTextField}
                  />
                </Grid>
              </Grid>
            </Container>

            <CpfInfos {...values} />

            <Container maxWidth='sm'>
              <Grid container spacing={3}>
                <Grid item xs={10} sm={10}>
                  <FormField
                    fullWidth
                    name='phoneNumber'
                    label='Celular'
                    variant='outlined'
                    onBlur={ev => {
                      handleBlurValidation({
                        errors,
                        fieldName: 'phoneNumber'
                      })
                      handleBlur(ev)
                      setFieldTouched('phoneNumber', true)
                    }}
                    component={PhoneTextField}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormField
                    type='email'
                    fullWidth
                    name='email'
                    label='E-mail'
                    variant='outlined'
                    component={TextField}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormField
                    label="Filial"
                    name="branchCode"
                    value={values.branchCode}
                    onChange={handleChangeBranchOffice({ handleChange, setFieldValue })}
                    onBlur={handleBlur}
                    options={branchesOffice}
                    component={Select}
                  />
                </Grid>

                <Hidden xsDown>
                  <Grid item sm={3} />
                </Hidden>

                <Grid item xs={12} sm={12} align='center'>
                  <Button
                    className={classes.root}
                    fullWidth
                    type='submit'
                    color='primary'
                    variant='contained'
                    disabled={
                      isSubmitting || !isValid
                    }
                  >
                    Adicionar Funcionário
                </Button>
                </Grid>
              </Grid>
            </Container>
          </Form>
        )}
    </Formik>
  )
}

EmployeeCreateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
}

export default memo(EmployeeCreateForm)
