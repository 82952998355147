import styled from 'styled-components'
import { flexBox } from '../../styles/mixins'
import media from 'styled-media-query';

export const Container = styled.div`
  ${flexBox('column nowrap', 'flex-start')};
  height: 100%;
  margin-left: 80px;
  width: calc(100% - 80px);
  overflow-x: hidden;
  overflow-y: auto;
  ${media.lessThan('767px')`
    margin-left: 0;
    width: 100%;
  `}
`
export const Main = styled.main`
  ${flexBox('row nowrap', 'flex-start', 'flex-start')};
  width: 100%;
  padding: 40px;
  margin-bottom: ${({hideFooter}) => hideFooter ? '20px' : '150px'};
  position: relative;
  ${media.lessThan('767px')`
    flex-flow: column nowrap;
    padding: 0;
    display: block;
    margin-bottom: 0;
    margin-top: 80px;
  `}
`

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  ${media.lessThan('767px')`
    height: 100%;
  `}
`;
