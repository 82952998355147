
export const SECONDARY = '#121a3d';
export const PRIMARY = '#fd3b61';
export const PRIMARY_LIGHT = '#F3F7FC';
export const CORRECT = '#0bad3d';
export const ALERT = '#d3931b';
export const ERROR = '#ad300b';

export const black = '#202020';
export const white = '#fff';
export const whiteThree = '#f8f8f8';
export const whiteFour = '#f0f0f0';
export const steel = '#7d8591';
export const blueTwo = '#3a52ed';
export const brownishGrey = '#6b6b6b';
export const greyishBrownThree = '#3f3f3f';
export const redPink = '#f04250';
export const LIGHT_GREY = '#f7f7f7';
export const GREY = '#ccc';
export const LABEL = '#6f6f6f';
export const TEXT = '#363636';
export const LINK = '#0091FF';


export const bgcolor = '#f7f7f7';