import React, { memo, useMemo, useState, useEffect, Fragment } from 'react'
import { Button, makeStyles, Snackbar, Fade } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import { ReactSVG } from 'react-svg'
import BarCode from '../../assets/svgs/barcode.svg'
import PicPay from '../../assets/svgs/picpay.svg'
import CreditCard from '../../assets/svgs/creditcard.svg'
import { PJ, PF, ORTHO } from '../../shared/constants/dentalServices'
import { geDetail } from '../../shared/services/plans'
import useSelfServiceRedirect from '../../hooks/useSelfServiceRedirect'
import { get } from 'lodash'
import PhoneIcon from '@material-ui/icons/Phone'

const useStyles = makeStyles(theme => ({
  root: {
    padding: `34px 0`,
    color: `#161A47`,
    position: 'relative',
    height: 'calc(100% + 50px)'
  },
  header: {
    display: `flex`,
    flexDirection: `column`,
    fontSize: 20,
    fontWeight: 300,
    color: `#161A47`,
    padding: `0 46px`
  },
  shareLink: {
    position: 'absolute',
    right: 68,
    top: 30,
    '@media(max-width: 600)': {
      right: 40
    }
  },
  headerSubtitle: {
    fontWeight: 600,
    fontSize: 32
  },
  proceduresSection: {
    marginTop: 38,
    padding: `0 46px`
  },
  proceduresList: {
    listStyle: 'none',
    display: 'flex',
    flexWrap: 'wrap'
  },
  procedureItem: {
    width: `50%`,
    marginBottom: 6,
    '&::before': {
      content: '"●"',
      color: 'red',
      marginRight: 7
    }
  },
  procedureFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 15,
    fontSize: 10
  },
  seeAllProcedures: {
    textDecoration: 'underline',
    color: '#008EFF',
    cursor: 'pointer'
  },
  proceduresLink: {
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  gracePeriodSection: {
    height: 24,
    backgroundColor: '#65D800',
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    justifyContent: 'center',
    fontSize: 9,
    marginTop: 37
  },
  servicesSection: {
    marginTop: 32,
    padding: `0 46px`
  },
  servicesTitle: {
    color: `#121A3D`,
    fontSize: 16,
    marginBottom: 16,
    display: 'block'
  },
  servicesDescription: {
    color: '#505050',
    fontSize: '12',
    paddingLeft: 15,
    marginTop: 3,
    marginBottom: 19
  },
  floaterFooter: {
    right: 0,
    bottom: 0,
    height: 96,
    position: 'fixed',
    backgroundColor: '#E9F0F8',
    maxWidth: '100%',
    width: 450
  },
  contractSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 30px'
  },
  priceSection: {
    display: 'flex',
    flexDirection: 'column',
    padding: '14px 16px 19px'
  },
  priceLabel: {
    fontSize: 12,
    fontWeight: 500
  },
  priceInfo: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 26,
    marginTop: 10,
    width: 90,
    alignItems: 'center'
  },
  or: {
    color: '#a8a8a8'
  },
  priceTitle: {
    display: 'flex',
    alignItems: 'center'
  }
}))

const icon = {
  boleto: BarCode,
  cartãodecrédito: CreditCard,
  picpay: PicPay
}

const PlanDetail = ({
  children,
  code,
  isCompany,
  hasOrthodontic,
  planTitle,
  needForm
}) => {
  const classes = useStyles()
  const [snackbarOpened, setSnackbarOpened] = useState(false)
  const [details, setDetails] = useState([])
  const [proceduresType, setProceduresType] = useState([])
  const [seeingAllProcedures, setSeeingAllProcedures] = useState(false)
  const [seeingDetailProcedure, setSeeingDetailProcedure] = useState(false)
  const [detailProcedure, setDetailProcedure] = useState({})
  const [name, setName] = useState('')
  const [paymentMethods, setPaymentMethods] = useState([])

  const { redirectToSelfService } = useSelfServiceRedirect({ code })

  const handleSelectProcedure = procedures => {
    setDetailProcedure(procedures)
    setSeeingDetailProcedure(prev => !prev)
    setSeeingAllProcedures(prev => !prev)
  }

  useEffect(() => {
    geDetail(code).then(result => {
      setDetails(result.details)
      setProceduresType(result.proceduresType)
      setPaymentMethods(result.paymentMethods)
      setName(result.name)
    })
  }, [code])

  const MAIN_PROCEDURES = useMemo(() => {
    let result = PF
    if (isCompany) {
      result = PJ
    }
    if (hasOrthodontic) {
      result = [...ORTHO, ...result]
    }
    return result
  }, [hasOrthodontic, isCompany])

  const proceduresToShow = useMemo(
    () => (seeingDetailProcedure ? detailProcedure : MAIN_PROCEDURES),
    [MAIN_PROCEDURES, seeingDetailProcedure, detailProcedure]
  )

  const Services = () => (
    <section className={classes.servicesSection}>
      {details.map(({ title, descriptions }, i) => (
        <Fragment key={title}>
          <strong className={classes.servicesTitle}>{title}</strong>
          <ul className={classes.proceduresList}>
            {descriptions.map(({ title, description }, i) => (
              <li
                style={{ width: '100%' }}
                className={classes.procedureItem}
                key={title}
              >
                <strong>{title}</strong>
                <p className={classes.servicesDescription}>
                  Atendimento domiciliar de urgência 24h por dia nos 365 dias do
                  ano, sem nenhum custo adicional!
                </p>
              </li>
            ))}
          </ul>
        </Fragment>
      ))}
    </section>
  )

  const Payments = () => (
    <section className={classes.servicesSection}>
      <strong className={classes.servicesTitle}>Formas de Pagamento</strong>
      <ul className={classes.proceduresList}>
        {paymentMethods.map(({ title, paymentOptions }, i) => (
          <li style={{ width: '100%', marginTop: i > 0 ? 30 : 0 }} key={title}>
            <strong className={classes.priceTitle}>
              {' '}
              <ReactSVG
                style={{ marginRight: 12 }}
                src={icon[title.toLowerCase().replace(/\s/gi, '')]}
              />{' '}
              {title}
            </strong>
            <section className={classes.priceInfo}>
              {paymentOptions.map(({ period, value }, i) => (
                <Fragment key={i}>
                  <span>
                    {value.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                    })}{' '}
                    /{period}
                  </span>
                  {i < paymentOptions.length - 1 && (
                    <i className={classes.or}>––– ou –––</i>
                  )}
                </Fragment>
              ))}
            </section>
          </li>
        ))}
      </ul>
    </section>
  )

  return (
    <section className={classes.root}>
      <article className={classes.header}>
        <span>Plano {name}</span>
        <strong className={classes.headerSubtitle}>{planTitle}</strong>
      </article>
      <section className={classes.proceduresSection}>
        <ul className={classes.proceduresList}>
          {seeingAllProcedures
            ? proceduresType.map(({ name, procedures }) => (
                <li className={classes.procedureItem} key={name}>
                  <button
                    className={classes.proceduresLink}
                    onClick={() => handleSelectProcedure(procedures)}
                  >
                    <strong>{name}</strong>
                  </button>
                </li>
              ))
            : proceduresToShow.map(name => (
                <li className={classes.procedureItem} key={name}>
                  <strong>{name}</strong>
                </li>
              ))}
        </ul>
        <section className={classes.procedureFooter}>
          <strong
            style={{
              visibility:
                seeingAllProcedures || seeingDetailProcedure
                  ? 'hidden'
                  : 'visible'
            }}
          >
            + 90 procedimentos
          </strong>
          <button
            onClick={() => {
              if (seeingDetailProcedure) {
                setSeeingDetailProcedure(false)
                setSeeingAllProcedures(true)
              } else if (seeingAllProcedures) {
                setSeeingDetailProcedure(false)
                setSeeingAllProcedures(false)
              } else {
                setSeeingDetailProcedure(false)
                setSeeingAllProcedures(true)
              }
            }}
            className={classes.seeAllProcedures}
          >
            {seeingAllProcedures || seeingDetailProcedure
              ? 'Voltar'
              : 'Ver todos'}
          </button>
        </section>
      </section>
      <article className={classes.gracePeriodSection}>
        <strong>Carência Zero &nbsp; </strong> {!isCompany && 'no plano anual'}
      </article>
      <Services />
      <Payments />
      <section className={classes.floaterFooter}>
        {needForm ? (
          <Button
            style={{
              height: 40,
              borderRadius: 50,
              padding: 0,
              margin: `25px 10% 0`,
              width: `80%`,
            }}
            fullWidth
            type='submit'
            color='primary'
            variant='outlined'
            aria-label='enviar'
            className='padding'
            component='a'
            href='tel:+552130306853'
          >
            <PhoneIcon /> (21) 3030-6853
          </Button>
        ) : (
          <section className={classes.contractSection}>
            <section className={classes.priceSection}>
              <span className={classes.priceLabel}>Apenas</span>
              <span>
                <strong style={{ fontSize: 16 }}>R$</strong>
                <strong style={{ fontSize: 26 }}>
                  {get(
                    paymentMethods,
                    '[0].paymentOptions[1].value',
                    0
                  ).toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL'
                  })}
                </strong>
              </span>
              <span>por /mês</span>
            </section>
            <Button
              style={{
                height: 40,
                borderRadius: 50,
                backgroundColor: '#121a3d',
                padding: 0,
                width: 115
              }}
              type='submit'
              color='primary'
              variant='contained'
              aria-label='enviar'
              className='padding'
              onClick={redirectToSelfService}
            >
              Contratar
            </Button>
          </section>
        )}
      </section>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpened}
        onClose={() => {
          setSnackbarOpened(false)
        }}
        TransitionComponent={Fade}
        message='Url copiada para enviar ao seu cliente'
        autoHideDuration={5000}
        action={<CheckIcon fontSize='default' />}
      />
    </section>
  )
}

export default memo(PlanDetail)
