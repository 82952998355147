import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core'
import Section from '../Section'
import { ReactSVG } from 'react-svg'
import WhatsApp from '../../assets/svgs/whatsapp.svg'
import Phone from '../../assets/svgs/phone.svg'

const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto',
    width: '80%',
    height: '70%',
    display: 'flex',
    '@media(max-width: 767px)': {
      flexFlow: 'column'
    }
  },
  items: {
    width: '50%',
    height: '100%',
    display: 'grid',
    fontSize: 18,
    gridColumnGap: '4%',
    gridTemplateColumns: '40px calc(96% - 40px)',
    gridTemplateRows: 'repeat(2, 50%)',
    alignItems: 'center',
    gridTemplateAreas: `
    "icon title"
    "icon link"
    `,
    margin: 'auto',
    '&:first-child': {
      borderRight: '1px solid #DDE0E5'
    },
    '&:not(:first-child)': {
      marginLeft: '10%'
    },
    '@media(max-width: 767px)': {
      width: '100%',
      '&:first-child': {
        borderRight: 'none'
      },
      '&:not(:first-child)': {
        marginLeft: '0'
      }
    }
  },
  link: {
    gridArea: 'link',
    alignSelf: 'start'
  },
  title: {
    gridArea: 'title',
    alignSelf: 'end'
  }
}))

const ITEMS = [
  {
    title: 'Central de Ajuda',
    link: 'tel:+5508000244330',
    textLink: '0800-024-4330',
    icon: Phone
  },
  {
    title: 'Via WhatsApp',
    link: 'https://wa.me/552130306853',
    textLink: '(21) 3030-6853',
    icon: WhatsApp
  }
]

const HelpCentralSection = ({ children }) => {
  const classes = useStyles()

  return (
    <Section title='Central de Ajuda'>
      <section className={classes.root}>
        {ITEMS.map(({ title, link, textLink, icon }) => (
          <section className={classes.items} key={textLink}>
            <ReactSVG src={icon} style={{ gridArea: 'icon' }} />
            <span className={classes.title}>{title}</span>
            <a
              href={link}
              rel='noopener noreferrer'
              target='_blank'
              className={classes.link}
            >
              {' '}
              <strong> {textLink} </strong>{' '}
            </a>
          </section>
        ))}
      </section>
    </Section>
  )
}

export default memo(HelpCentralSection)
