import styled from 'styled-components'
import { SECONDARY, CORRECT, ALERT, ERROR } from '../../shared/styles/colors'
import { Grid, Drawer as MuiDrawer } from '@material-ui/core'
import media from 'styled-media-query'

export const Header = styled.header`
  font-size: 2rem;
  color: ${SECONDARY};
  padding: 0 46px;
  height: 136px;
  align-items: center;
  box-shadow: 0px 2px 6px #0000000d;
  display: flex;
  justify-content: space-between;
  ${media.lessThan('767px')`
    font-size: 2rem;
    padding: 0 20px;
  `}
`

export const Detail = styled.section`
  .drawer {
    .MuiPaper-root {
      background-color: #f6f6f6;
    }
  }
`

export const Drawer = styled(MuiDrawer)`
  .MuiDrawer-paper {
    flex-direction: row;
    ${media.lessThan('767px')`
      width: 100%;
    `}
  }
`

export const DetailWrapper = styled.section`
  width: 450px;
  background-color: #f6f6f6;
  height: auto;
  min-height: 100%;
  overflow: hidden;
  ${({ hasDivisorLeft }) => hasDivisorLeft && `border-left: 1px solid black`}
  ${media.lessThan('767px')`
    width: 100%;
  `}
`

export const DetailMain = styled(Grid)`
  padding: 46px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 0 46px;
`

export const Label = styled.span`
  color: '#ACACAC';
  font-size: 0.75rem;
  display: block;
`

export const Value = styled.span`
  color: '#000';
  font-size: 1rem;
  font-weight: 500;
`

export const Title = styled.span`
  color: '#000';
  font-size: 1.25rem;
  font-weight: 300;
`

export const Link = styled.span`
  color: '#000';
  font-size: 0.75rem;
  font-weight: 300;
  color: #0a93e8;
  text-decoration: underline;
  cursor: pointer;
`

export const LabelAddress = styled.span`
  font-size: 0.8rem;
  letter-spacing: 0;
  color: #acacac;
`
export const ValueText = styled.span`
  text-align: left;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0px;
  color: #000000;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
`

const getColor = status => {
  switch (status) {
    case 1:
      return `${CORRECT}`
    case 2:
      return `${ALERT}`
    default:
      return `${ERROR}`
  }
}

export const TextBadge = styled.article`
  position: absolute;
  top: 9px;
  right: 20px;
  border: 1px solid ${({ status }) => getColor(status)};
  color: white;
  background: ${({ status }) => getColor(status)};
  border-radius: 4px;
  width: 96px;
  text-align: center;
  font-size: 0.85rem;
  padding: 4px 0;
`

export const OpenInvoice = styled.a`
  text-align: center;
  text-decoration: underline;
  color: #3a52ed;
  font-size: 0.8rem;
  &:hover {
    text-decoration: underline;
    color: #3a52ed;
  }
`
