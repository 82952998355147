import { get } from 'lodash'
import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { Container, Grid, TextField } from '@material-ui/core'
import ZipCodeField from '../ZipCodeField'
import FormField from '../FormField'
import LinkButton from '../LinkButton'
import ContrastedContainer from '../ContrastedContainer'
import DataField from '../DataField'
import { getAddress } from '../../shared/services/address'
import { isCEP } from 'brazilian-values'
import SearchAddressForm from '../Forms/SearchAddressForm'
import Dialog from '../Dialog'
import useDialog from '../../hooks/useDialog'
import * as Styled from './styled'
import {
  handleBlurValidation,
  dispatchClickEvent
} from '../../shared/services/gtmEventDispatcher'

const AddressFormFields = ({ children, formikProps, relatedTo, title }) => {
  const [specificCepError, setSpecificCepError] = useState()
  const [hasStreetInRequest, setHasStreetInRequest] = useState(true)
  const [hasNeighborhoodInRequest, setHasNeighborhoodInRequest] = useState(true)
  const { open, handleOpen, handleClose } = useDialog()
  const { values, setFieldValue, setFieldError, handleChange } = formikProps

  const handleClickOpenDialog = event => {
    dispatchClickEvent({ label: event.target.innerText, id: event.target.id })
    handleOpen()
  }

  const handleChangeCep = async event => {
    const { value = '' } = event.target
    handleChange(event)
    setSpecificCepError()

    if (isCEP(value)) {
      try {
        const response = await getAddress(value)
        if (!response.cep) {
          throw new Error('Endereço não encontrado')
        }
        setHasStreetInRequest(!!response.street)
        setHasNeighborhoodInRequest(!!response.neighborhood)
        setFieldValue(relatedTo, { ...response, cep: value, complement: '' })
      } catch (error) {
        setFieldError(`${relatedTo}.cep`, error.message)
        setFieldValue(relatedTo, { cep: value })
        setSpecificCepError('Informe um cep válido')
      }
    }
  }

  const handleSelectCepFromSearch = ({ cep = '' } = {}) => {
    formikProps.setFieldValue(`${relatedTo}.cep`, cep.replace('-', ''))
    handleClose()
  }

  return (
    <>
      <Container maxWidth='sm'>
        <Grid container spacing={3}>
          {title && (
            <Grid item xs={12}>
              <Styled.Title variant='h6' component='h2'>
                {title}
              </Styled.Title>
            </Grid>
          )}
          <Grid item xs={12}>
            <FormField
              fullWidth
              label='CEP'
              variant='outlined'
              name={`${relatedTo}.cep`}
              value={get(values, `${relatedTo}.cep`)}
              component={ZipCodeField}
              onChange={handleChangeCep}
              specificerror={specificCepError}
              onBlur={() => {
                handleBlurValidation({
                  errors: formikProps.errors,
                  fieldName: `${relatedTo}.cep`,
                  extraValidationKeys: [
                    `${relatedTo}.street`,
                    `${relatedTo}.city`,
                    `${relatedTo}.UF`
                  ]
                })
                formikProps.handleBlur({ target: { name: `${relatedTo}.cep` } })
                // formikProps.setFieldTouched(`${relatedTo}.cep`, true);
              }}
              InputProps={{
                endAdornment: (
                  <LinkButton
                    variant='caption'
                    onClick={handleClickOpenDialog}
                    type='button'
                    id={`openSearchAddressDialog${relatedTo}`}
                  >
                    Não sabe o CEP?
                  </LinkButton>
                )
              }}
            />
          </Grid>

          {get(values, `${relatedTo}.cep`) &&
            get(values, `${relatedTo}.city`) &&
            !hasStreetInRequest && (
              <Grid item xs={12}>
                <FormField
                  fullWidth
                  label={'Seu Logradouro'}
                  variant='outlined'
                  name={`${relatedTo}.street`}
                  component={TextField}
                />
              </Grid>
            )}
          {get(values, `${relatedTo}.cep`) &&
            get(values, `${relatedTo}.city`) &&
            !hasNeighborhoodInRequest && (
              <Grid item xs={12}>
                <FormField
                  fullWidth
                  label={'Seu Bairro'}
                  variant='outlined'
                  name={`${relatedTo}.neighborhood`}
                  component={TextField}
                />
              </Grid>
            )}
        </Grid>
      </Container>
      {hasStreetInRequest && get(values, `${relatedTo}.street`, false) && (
        <ContrastedContainer>
          <Grid container spacing={1} style={{ padding: '12px 0' }}>
            <Grid item xs={12}>
              <DataField
                label='Endereço'
                text={`${get(values, `${relatedTo}.street`, '')}
${get(values, `${relatedTo}.city`)} - ${get(values, `${relatedTo}.UF`)}`}
              />
            </Grid>
          </Grid>
        </ContrastedContainer>
      )}
      <Container
        maxWidth='sm'
        style={{
          marginTop: !get(values, `${relatedTo}.street`, false) && '12px'
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              fullWidth
              label='Número'
              variant='outlined'
              name={`${relatedTo}.number`}
              component={TextField}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormField
              fullWidth
              label='Complemento'
              variant='outlined'
              name={`${relatedTo}.complement`}
              component={TextField}
            />
          </Grid>
          {children}
        </Grid>
      </Container>

      <Dialog open={open} onClose={handleClose} title='Não sabe seu CEP?'>
        <SearchAddressForm onSelect={handleSelectCepFromSearch} />
      </Dialog>
    </>
  )
}

AddressFormFields.defaultProps = {
  relatedTo: 'address',
  title: ''
}

AddressFormFields.propTypes = {
  relatedTo: PropTypes.oneOf(['address']),
  title: PropTypes.string
}

export default memo(AddressFormFields)
