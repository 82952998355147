import { get } from 'lodash';
import React, { memo, useEffect, useRef, useState } from 'react';
import Autocomplete from '../../Autocomplete';
import { getCities } from '../../../shared/services/address';
import {
  Button,
  Container,
  Grid,
  TextField,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemSecondaryAction,
  Radio,
  Typography
} from '@material-ui/core';
import useDebounce from '../../../hooks/useDebounce';
import { getCep } from '../../../shared/services/address';
import * as Styled from './styled';
import { dispatchFormFieldFillEvent, dispatchClickEvent } from '../../../shared/services/gtmEventDispatcher';

const SearchAddressForm = ({ onSelect }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const streetInput = useRef(null);
  const [uf, setUf] = useState('');
  const [city, setCity] = useState('');
  const [street, setStreet] = useState('');
  const [options, setOptions] = useState([]);
  const debouncedStreet = useDebounce(street, 350);

  const handleClick = () => {
    dispatchClickEvent({ label: 'Concluir', type: 'button', id: 'searchAddressSubmitButton' });
    onSelect(selectedOption);
  }

  const handleChangeAutocomplete = (_, inputValue) => {
    const { value } = inputValue || {};
    setUf(get(value, 'UF', ''));
    setCity(get(value, 'city', ''));

    if (value) {
      dispatchFormFieldFillEvent({ label: 'Selecione o município' })
      streetInput.current.querySelector('input').focus()
    }
  }

  useEffect(() => {
    let active = true;
    setOptions([]);

    if (active && uf && city && debouncedStreet.length >= 3) {
      dispatchFormFieldFillEvent({ label: 'Digite o endereço' })
      getCep({ UF: uf, city, street: debouncedStreet })
        .then(setOptions);
    }

    return () => {
      active = false;
    }
  }, [debouncedStreet, uf, city])

  return (
    <Styled.Wrapper>
      <Container maxWidth="sm">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Styled.Text variant="body1" align="center">
              Digite abaixo seu endereço para localizarmos seu CEP
            </Styled.Text>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete label="Selecione o município" onChange={handleChangeAutocomplete} service={getCities} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Digite o endereço"
              onChange={(event) => { setStreet(event.target.value) }}
              variant="outlined"
              ref={streetInput}
            />
          </Grid>
        </Grid>
      </Container>

      {
        options.length > 0 && (
          <Styled.ItemsContainer maxWidth="sm">
            <List>
              {options.map((option, index) => (
                <React.Fragment key={`addressItem${index}`}>
                  <ListItem button id={`addressItem${index}`} alignItems="flex-start" onClick={() => {
                    dispatchClickEvent({ label: option.street, type: 'listItem', id: `addressItem${index}` });
                    setSelectedOption(option);
                  }}>
                    <ListItemText
                      primary={
                        <>
                          <Typography variant="body1" display="block">
                            {option.street}
                          </Typography>
                          <Typography variant="caption" display="block">
                            {`${option.neighborhood ? `${option.neighborhood} . ` : ''}${option.city} - ${option.UF}`}
                          </Typography>
                        </>
                      }
                      secondary={
                        <Typography variant="subtitle2" component="strong" display="block">
                          {option.cep}
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <Radio checked={selectedOption === option} />
                    </ListItemSecondaryAction>
                  </ListItem>
                  {index < options.length - 1 &&
                    <Divider component="li" />
                  }
                </React.Fragment>
              ))}
            </List>
          </Styled.ItemsContainer>
        )
      }

      <Container style={{ marginTop: '12px' }}>
        <Grid container spacing={4}>
          <Grid item xs={12} align="center">
            <Button
              id="searchAddressSubmitButton"
              fullWidth
              type="button"
              variant="contained"
              color='primary'
              disabled={!selectedOption}
              onClick={handleClick}
              style={{ maxWidth: '280px' }}
            >
              Concluir
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Styled.Wrapper>
  );
};

export default memo(SearchAddressForm);