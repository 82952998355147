import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core'
import EmployeeEditForm from '../Forms/EmployeeEditForm'
import EmployeesCommission from '../../assets/svgs/employees-commission.svg'
import EmployeesLifes from '../../assets/svgs/employees-lifes.svg'
import EmployeesSold from '../../assets/svgs/employees-sold.svg'

const useStyles = makeStyles(theme => ({
  root: {
    padding: `34px 0`,
    color: `#161A47`,
    position: 'relative',
    height: 'calc(100% + 160px)',
    width: '100%',
  },
  infos: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 46px',
    marginBottom: 24,
    borderBottom: '1px #ACACAC solid',
  },
  infosValue: {
    display: 'flex',
    WebkitAlignItems: 'center',
  },
  header: {
    display: `flex`,
    flexDirection: `column`,
    fontSize: 20,
    fontWeight: 300,
    color: `#161A47`,
    padding: `0 46px`
  },
  data: {
    padding: '30px 46px',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 30px)',
    gridGap: '16px 10px',
    gridTemplateAreas: `
        "name name name name"
        "cpf cpf cpf birthDate"
        "signupDate signupDate signupDate ."`,
  },
  birthDate: {
    gridArea: 'birthDate'
  },
  signupDate: {
    gridArea: 'signupDate'
  },
  name: {
    gridArea: 'name',
    color: '#ACACAC',
    fontSize: '12px'
  },
  cpf: {
    gridArea: 'cpf'
  },
  value: {
    fontSize: 16,
    fontWeight: 500,
    color: 'black'
  },
}))

const ICON = {
  commission: EmployeesCommission,
  lifes: EmployeesLifes,
  sell: EmployeesSold
}

// const getInfos = ({ lifes = 0, sell = 0, commission = 0 }) => ([
//   { label: 'Vendas', value: lifes, icon: ICON['commission'] },
//   { label: 'Vidas', value: sell, icon: ICON['lifes'] },
//   { label: 'Commissão', value: commission, icon: ICON['sell'] },
// ])

const EmployeeEdit = ({
  selectedEmployee,
  updateEmployees,
  branchesOffice,
}) => {
  const classes = useStyles()

  const onSubmitForm = (values) => {
    updateEmployees(selectedEmployee.code, values)
  }

  return (
    <section className={classes.root}>
      {/* <section className={classes.infos}>
        {infos.map(({ label, value, icon }) => (
          <article key={label}>
            <span>{label}</span>
            <article className={classes.infosValue}> <ReactSVG src={icon} style={{ marginRight: 10 }} /> <strong>{value} </strong></article>
          </article>
        ))}
      </section> */}
      <article className={classes.header}>
        <span>Dados Pessoais</span>
      </article>
      <section className={classes.data}>

        <article className={classes.signupDate}>
          <span>Data de Cadastro</span>
          <p className={classes.value}> {selectedEmployee.signupDate}</p>
        </article>


        <article className={classes.name}>
          <span>Nome</span>
          <p className={classes.value}>{selectedEmployee.name}</p>
        </article>

        <article className={classes.cpf}>
          <span>CPF</span>
          <p className={classes.value}>{selectedEmployee.cpf}</p>
        </article>

        <article className={classes.birthDate}>
          <span>Nascimento</span>
          <p className={classes.value}>{new Date(selectedEmployee.dateOfBirth).toLocaleDateString('pt-Br')}</p>
        </article>
      </section>
      {selectedEmployee.phoneNumber && (branchesOffice && branchesOffice.length) && <EmployeeEditForm {...selectedEmployee} onSubmit={onSubmitForm} branchesOffice={branchesOffice}/>}

    </section>
  )
}

export default memo(EmployeeEdit)
