import React, { useState, memo, useMemo, useCallback } from 'react'
import * as Styled from './Sidemenu.style'
import { getItem } from '../../utils/storage'
import { getInitials } from '../../utils/getInitialsName'
import { ReactSVG } from 'react-svg'
import Logo from '../../assets/svgs/logo-sidemenu.svg'
import girl from '../../assets/images/girl.png'
import Home from '../../assets/svgs/icon-home.svg'
import { useMediaQuery, useTheme, Tooltip } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import MenuMobile from '../MenuMobile'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import BusinessIcon from '@material-ui/icons/Business'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import { get } from 'lodash'


const SidemenuComponent = ({ logOut }) => {
  const history = useHistory()
  const user = useSelector(state => state.userReducer.user)
  const fullNameInitials = useMemo(() => getInitials(user.fullName), [
    user.fullName
  ])
  const [openedMenu, setOpenMenu] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  const makeLinks = useCallback(() => {
    const userSaved = getItem('user') 
    const result = [
      {
        to: '/dashboard',
        isActive: 1,
        title: 'Home',
        icon: () => <ReactSVG src={Home} />
      },
    ];
    const modules = get(userSaved, 'modules', [])

    if(modules.find(module => module === 'branch' )){
      result.push(    {
        to: '/filiais',
        isActive: 0,
        title: 'Filiais',
        icon: () => <BusinessIcon style={{ color: '#fff', fontSize: '2rem' }} />
      })
    }
    if(modules.find(module => module === 'employee' )){
      result.push(    {
        to: '/funcionarios',
        isActive: 0,
        title: 'Funcionários',
        icon: () => (
          <PersonOutlineIcon style={{ color: '#fff', fontSize: '2.3rem' }} />
        )
      })
    }
    return result
  }, [])

  const LINKS = useMemo(() => makeLinks(), [user])

  const getLinks = pathName => {
    return LINKS.map((obj, i) => ({
      ...obj,
      isActive: Number(pathName === obj.to)
    }))
  }

  const links = getLinks(history.location.pathname)

  return (
    <Styled.MenuBarWrapper>
      <Styled.LogoWrapper>
        <ReactSVG src={Logo} />
      </Styled.LogoWrapper>

      {!isMobile ? (
        <>
          <Styled.MenuBarGroup>
            {links.map(({ to, isActive, title, icon: Icon }, i) => (
              <Tooltip title={title} placement='right' key={to}>
                <Styled.MenuBarLink
                  key={i}
                  to={to}
                  activeClassName='current'
                  active={isActive}
                >
                  <Styled.MenuBarItem>
                    <Icon />
                  </Styled.MenuBarItem>
                </Styled.MenuBarLink>
              </Tooltip>
            ))}
          </Styled.MenuBarGroup>

          <Styled.MenuBarAvatar to='/perfil' >
            <Styled.Avatar alt='Usuario' src={girl} />
            <Styled.AvatarName>{fullNameInitials}</Styled.AvatarName>
          </Styled.MenuBarAvatar>
        </>
      ) : (
          <>
            <Styled.MenuButton
              onClick={() => {
                setOpenMenu(true)
              }}
              size='medium'
            >
              <MenuIcon />
            </Styled.MenuButton>
            <MenuMobile links={links} opened={openedMenu} setOpened={setOpenMenu}>
              <Styled.MenuBarAvatar to='/perfil' onBottom>
                <Styled.Avatar alt='Usuario' src={girl} />
                <Styled.AvatarName>{fullNameInitials}</Styled.AvatarName>
              </Styled.MenuBarAvatar>
            </MenuMobile>
          </>
        )}
    </Styled.MenuBarWrapper>
  )
}

export const Sidemenu = memo(SidemenuComponent)
