import { object, string } from 'yup';
import { isCEP } from 'brazilian-values';

const isValidCep = ['is-valid-cep', 'Informe um CEP váslido', (value = '') => value ? isCEP(value.replace(/\D/g, '')) : true];

const addressValidation = object().shape({
  cep: string()
    .min(8, 'Informe um CEP válido')
    .test(...isValidCep)
    .required('Informe o CEP'),
  street: string().required('Informe a rua'),
  number: string().required('Informe o número'),
  complement: string().nullable(true),
});

export const validationSchema = object().shape({
  address: addressValidation,
});
