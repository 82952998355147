import React, { memo, useMemo } from 'react'
import { makeStyles, useTheme, useMediaQuery } from '@material-ui/core'
import { ReactSVG } from 'react-svg'
import Heart from '../../assets/svgs/heart.svg'
import Handshake from '../../assets/svgs/handshake.svg'
import Payment from '../../assets/svgs/payment.svg'

const ICON = {
  heart: Heart,
  totalSold: Handshake,
  totalGain: Payment
}

const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto 0',
    marginLeft: 20,
    display: 'grid',
    gridTemplateAreas: `
    "icon title"
    "icon subtitle"
    `,
    gridTemplateColumns: '34px auto',
    gridTemplateRows: '14px 25px;',
    alignItems: 'center',
    width: 'auto',
    gridGap: '0 7px',
    '@media(min-width: 400px)': {
      gridTemplateRows: '22px 40px',
      width: 'unset',
      gridGap: '0 31px',
      gridTemplateColumns: '76px 145px',
      gridTemplateAreas: `
      "icon title"
      "icon subtitle"
      `,
    },
  },
  diff: {
    '@media(max-width: 400px)': {
      gridTemplateAreas: `
      "title title"
      "icon icon"
      "subtitle subtitle"
      `,
      gridGap: '4px 0',
    }
  },
  icon: {
    gridArea: 'icon',
    '@media(max-width: 400px)': {
      justifySelf: 'center',    
    }
  },
  title: {
    gridArea: 'title',
    fontSize: 18,
    '@media(max-width: 400px)': {
      justifySelf: 'center',
      fontSize: 10,
    }
  },
  subtitle: {
    gridArea: 'subtitle',
    fontSize: 32,
    '@media(max-width: 767px)': {
      justifySelf: 'center',    
      fontSize: 20,
    }
  }
}))

const Item = ({ icon, title, value, isDifferentOnMobile }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(400))
  const svgStyle = useMemo(
    () =>
      isMobile ? 'width: 34px; height: 24px;' : 'width: 52px; height: 52px;',
    [isMobile]
  )
  return (
    <section className={`${classes.root} ${isDifferentOnMobile ? classes.diff : ''}`}>
      <article className={classes.icon}>
        <ReactSVG
          src={ICON[icon]}
          loading={() => <span className={classes.title}>Loading...</span>}
          beforeInjection={svg => {
            svg.classList.add('svg-mobile')
            svg.setAttribute('style', svgStyle)
          }}
        />
      </article>
      <span className={classes.title}> {title} </span>
      <strong className={classes.subtitle}> {value} </strong>
    </section>
  )
}

export default memo(Item)
