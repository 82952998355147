import { css } from 'styled-components';

export function flexBox(flexFlow = 'row wrap', justifyContent = 'center', alingItems = 'center') {
  return `
    display: flex;
    flex-flow: ${flexFlow};
    justify-content: ${justifyContent};
    align-items: ${alingItems};
  `;
}

export function fontFace(family, file, weight = 'normal') {
  return `
    @font-face {
      font-family: ${family};
      src: url(${file});
      font-weight: ${weight};
    }
  `;
}

export function fontSize(sizeInPixel = 16) {
  return `font-size: ${sizeInPixel / 16}rem;`;
}

export const rotate = (deg = 180) => `transform: rotate(${deg}deg);`

const breakpoints = {
	extraSmall: '575.98px',
	small: '767.98px',
	medium: '991.98px',
	large: '1199.98px'
};

export const device = Object.keys(breakpoints).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
		@media (max-width: ${breakpoints[label]}) {
			${css(...args)};
		}
	`;
  return accumulator;
}, {});

