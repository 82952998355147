export default class Address {
  constructor(obj = {}) {
    const { cep, street, number, complement, neighborhood, city, UF, CEP } = obj;

    this.cep = cep || CEP;
    this.street = street;
    this.number = number;
    this.complement = complement;
    this.neighborhood = neighborhood;
    this.city = city;
    this.UF = UF;
  }
}