import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Grid, TextField, Button } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';
import DocumentTextField from '../DocumentTextField'
import FormField from '../FormField';
import validationSchema from './validationSchema';
import { login } from '../../shared/services/login';
import { userAction } from '../../store/actions/user';
import { get } from 'lodash';

const NormalLoginForm = ({ history, setUser, setData, setNeedUpdate, setAlert }) => {


  async function handleSubmit(body, actions) {
    if (actions.validateForm()) {
      setData(body)
      try {
        const result = await login(body)
        setUser(result)
        history.go('/dashboard')
      } catch (e) {
        const { messages, data } = e;
        const isTemporaryPassword = get(data, 'value.isTemporaryPassword', false)
        setAlert({ severity: 'error', opened: true, msg: get(e, 'messages[0].text', 'É necessário cadastrar uma nova senha') })
        if (isTemporaryPassword) {
          setNeedUpdate(true)
        } else {
          actions.setFieldError(messages[0].property.toLowerCase(), messages[0].text);
          actions.setFieldValue('password', '');
        }
      }
    }
  }

  return (
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ identification: '', password: '' }}
        validationSchema={validationSchema}
        validationOnChange={false}
      >
        {({ values, ...formikProps }) => (
          <Form style={{ width: '100%' }}>
            <Grid container spacing={2} direction='column'>
              <Grid item xs={12}>
                <FormField
                  fullWidth
                  variant='outlined'
                  label='Seu CPF/CNPJ'
                  name='identification'
                  data-id='identification'
                  component={DocumentTextField}
                />
              </Grid>

              <Grid item xs={12}>
                <FormField
                  type='password'
                  fullWidth
                  variant='outlined'
                  label='Senha'
                  data-id='pass'
                  name='password'
                  component={TextField}
                />
              </Grid>
              <Grid
                item
                xs={12}
                align={'center'}
              >
                <Button
                  style={{ height: 40, borderRadius: 50, backgroundColor: '#121a3d' }}
                  fullWidth
                  type='submit'
                  color='primary'
                  variant='contained'
                  aria-label='enviar'
                  name='login-button'
                  className='padding'
                >
                  Entrar
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
  )
}

const mapStateToProps = state => ({
  ...state.userReducer
})

const mapDispatchToProp = dispatch => ({
  setUser: quetions => dispatch(userAction(quetions))
})

const LoginForm = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProp)
)(NormalLoginForm)

export default LoginForm
