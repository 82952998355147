import styled from 'styled-components'
import { flexBox } from '../../styles/mixins'
import media from 'styled-media-query';

export const Title = styled.span`
  font-size: 1.625rem;
  font-weight: 300;
  margin-bottom: 46px;
`
export const PaymentType = styled.span`
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 7px;
`
export const Main = styled.main`
  ${flexBox('column nowrap', 'flex-start', 'center')};
  height: 100%;
  width: 100%;
  ${media.lessThan('767px')`
    margin-left: 0;
    margin-top: 40px;
    padding-bottom: 130px;
    .MuiTabs-flexContainer {
      justify-content: center;
      margin-top: 10px;
    }
  `}

  .MuiAppBar-colorPrimary {
    background: transparent;
    box-shadow: none;
  }

  .MuiButtonBase-root {
    text-transform: none;
    padding: 0;
    &.Mui-selected {
      font-weight: 600;
    }
  }

  .MuiBox-root {
    padding: 19px 0 0!important;
  }

  .MuiTabs-indicator {
    height: 3px;
  }
`
