import React from 'react'
import { Switch, Redirect } from 'react-router-dom'

import Route from './Route'

import Login from '../pages/Login'
import RecoverPassword from '../pages/RecoverPassword'
import store from '../store'
import { PRIVATE_ROUTES, SIGNUP_ROUTES } from './constants'

export default function Routes () {
  const { user } = store.getState().userReducer

  return (
    <Switch>
      <Route
        exact
        path='/'
        signed={!!(user && user.accessToken)}
        render={() => (
          <Redirect
            to={(user && user.accessToken) ? '/dashboard' : '/login'}
          />
        )}
      />
      <Route
        exact
        path='/login'
        signed={!!(user && user.accessToken)}
        component={Login}
      />
      <Route
        exact
        path='/trocar-senha'
        signed={!!(user && user.accessToken)}
        component={RecoverPassword}
      />
      {SIGNUP_ROUTES.map(
        ({ path, component, step, title, hideHeader, noMargin, maxSteps }) => (
          <Route
            key={path}
            signed={!!(user && user.accessToken)}
            path={path}
            component={component}
            isSignup
            step={step}
            maxSteps={maxSteps}
            title={title}
            hideHeader={hideHeader}
            noMargin={noMargin}
          />
        )
      )}
      {PRIVATE_ROUTES.map(({ path, component }) => (
        <Route
          key={path}
          signed={!!(user && user.accessToken)}
          path={path}
          component={component}
          isPrivate
        />
      ))}
    </Switch>
  )
}
