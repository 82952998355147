import React, { memo } from 'react'
import { Formik, Form } from 'formik'
import { Button, Container, Grid } from '@material-ui/core'
import AddressFormFields from '../../AddressFormFields'
import { validationSchema } from './validationSchema'
import { useSelector } from 'react-redux'
import { useStyles } from '../muiStyle'

const AddressForm = ({ onSubmit }) => {
  const classes = useStyles()
  const { address } = useSelector(state => state.signupReducer.signup)

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    try {
      const payload = { ...values }
      if (payload.isBillingAddress) {
        delete payload.billingAddress
      }
      delete payload.isBillingAddress
      await onSubmit(payload)
    } catch (error) {
      // handleError(error.title || error.message)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={{
        address
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {formikProps => (
        <Form>
          <AddressFormFields
            formikProps={formikProps}
            relatedTo={'address'}
          ></AddressFormFields>

          <Container maxWidth='sm' style={{ marginTop: '12px' }}>
            <Grid container spacing={3} justify='center'>
              <Grid item xs={12} sm={12}>
                <Button
                  fullWidth
                  className={classes.root}
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={formikProps.isSubmitting || !formikProps.isValid}
                >
                  Próximo
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Form>
      )}
    </Formik>
  )
}

export default memo(AddressForm)
