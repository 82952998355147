import React, { memo } from 'react'
import { useHistory } from 'react-router-dom'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { Container, Toolbar } from '@material-ui/core'
import * as Styled from './styled'
import { Link } from 'react-router-dom'
import LogoLinear from '../../../assets/images/logo_white_rectangular.png'

const Header = ({ step, title, noMargin, maxSteps }) => {
  const history = useHistory()
  return (
    <Styled.Wrapper
      position='static'
      color='inherit'
      nomargin={Number(noMargin)}
    >
      <Styled.TopHeader>
        <Link to='/'>
          {' '}
          <img src={LogoLinear} height={35} alt='Logo W.Dental' />
        </Link>
      </Styled.TopHeader>
      <Container maxWidth='sm'>
        {title && (
          <Toolbar disableGutters align='center'>
            {!!step && (
              <Styled.IconButton
                disableRipple
                disableFocusRipple
                edge='start'
                onClick={() => {
                  history.goBack()
                }}
              >
                <ChevronLeftIcon fontSize='large' />
              </Styled.IconButton>
            )}
            <Styled.TitleContainer>
              <Styled.Title variant='h6' component='h1' align='center'>
                {title}
              </Styled.Title>
              {!!step && (
                <Styled.StepCaption
                  variant='caption'
                  component='h2'
                  align='center'
                >{`Passo ${step}/${maxSteps}`}</Styled.StepCaption>
              )}
            </Styled.TitleContainer>
          </Toolbar>
        )}
        {!!step && (
          <Styled.Steps>
            {new Array(maxSteps).fill(undefined).map((_, index) => (
              <Styled.Step
                key={`step-${index + 1}`}
                checked={Number(index + 1 <= step)}
              />
            ))}
          </Styled.Steps>
        )}
      </Container>
    </Styled.Wrapper>
  )
}

Header.defaultProps = {
  step: 1,
  maxSteps: 4,
  title: 'Dados Pessoais',
  noMargin: false
}

export default memo(Header)
