import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Button, Container, Grid, Hidden, TextField } from '@material-ui/core'
import { Formik, Form } from 'formik'
import FormField from '../../FormField'
import validationSchema from './validationSchema'
import { useStyles } from '../muiStyle'
import AddressFormFields from '../../AddressFormFields'

const BranchOfficeCreateForm = ({ onSubmit }) => {

  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        name: '',
        address: {},
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(formikProps) => (
          <Form className={classes.form}>
            <Container maxWidth='sm'>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormField
                    fullWidth
                    name='name'
                    label='Nome'
                    variant='outlined'
                    onBlur={event => {
                      formikProps.handleBlur(event)
                      formikProps.setFieldTouched('name', true)
                    }}
                    component={TextField}
                  />
                </Grid>
              </Grid>
            </Container>

            <AddressFormFields
              formikProps={formikProps}
              relatedTo={'address'}
            ></AddressFormFields>

            <Container maxWidth='sm'>
              <Grid container spacing={3}>

                <Hidden xsDown>
                  <Grid item sm={3} />
                </Hidden>

                <Grid item xs={12} sm={12} align='center'>
                  <Button
                    className={classes.root}
                    fullWidth
                    type='submit'
                    color='primary'
                    variant='contained'
                    disabled={
                      !formikProps.dirty || formikProps.isSubmitting || !formikProps.isValid
                    }
                  >
                    Adicionar Filial
                </Button>
                </Grid>
              </Grid>
            </Container>
          </Form>
        )}
    </Formik>
  )
}

BranchOfficeCreateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
}

export default memo(BranchOfficeCreateForm)
