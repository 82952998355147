import { httpHandler } from '../httpHandler';

export const put = (body) =>
  httpHandler.put({ url: '/user', options: { body } })

export const changePassword = (body) =>
  httpHandler.put({ url: '/user/change-password', options: { body } })

export const authenticate = (body) =>
  httpHandler.post({ url: '/user/authenticate', options: { body } })

