import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { formatToCPF, formatToCNPJ } from 'brazilian-values';
import MaskedTextField from '../MaskedTextField';

const getFormat = (documentType = 'cpf') => (value = '') => {
  switch (documentType) {
    case 'cnpj':
      return formatToCNPJ(value.substring(0, 14));
    case 'cpf':
      return formatToCPF(value.substring(0, 11));
    default:
      return value.length <= 11
        ? formatToCPF(value.substring(0, 11))
        : formatToCNPJ(value.substring(0, 14));
  }
}

const DocumentTextField = ({ documentType, ...props }) => {
  const format = useMemo(() => getFormat(documentType), [documentType]);

  return (
    <MaskedTextField
      {...props}
      type="tel"
      format={format}
      customInput={TextField}
    />
  );
}

DocumentTextField.propTypes = {
  documentType: PropTypes.oneOf(['cnpj', 'cpf', 'dynamic']),
}

DocumentTextField.defaultProps = {
  documentType: 'dynamic'
};

export default memo(DocumentTextField);