import { httpHandler } from '../httpHandler'

export const getAddress = cep => httpHandler.get({url: `/cep/${cep}`});

export const getCities = (searchTerm = '')  => httpHandler.get({url: `/cep/city/${searchTerm}`});

export const getCep =  (body)  => httpHandler.get({url: `/cep/filter`, params: body });

export const update = ({ code, body }) =>
  httpHandler.put({ url: `/w/seller/${code}/signup/address`, options: { body } })

export const create = body => httpHandler.post({url: `/w/seller/signup/address`, options: { body } });

