import httpHandler from './httpHandler'

export const login = body =>
  httpHandler.post({ url: '/user/login', options: { body } })
export const recover = body =>
  httpHandler.put({ url: '/user/recover-password', options: { body } })
export const userByRecoverPassword = code =>
  httpHandler.get({
    url: '/user/user-by-recover-password',
    params: { recoverPasswordCode: code }
  })
export const changePassword = body =>
  httpHandler.put({ url: '/user/change-password', options: { body } })
