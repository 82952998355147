export default class Company {
    constructor({
        name,
        businessName,
        phoneNumber,
        cnpj,
        email,
        fantasyName,
        openingDate,
    }) {
        this.name = name
        this.businessName = businessName
        this.phoneNumber = phoneNumber
        this.CNPJ = cnpj
        this.email = email
        this.name = fantasyName
        this.openingDate = openingDate
    }
  }