import styled, { css } from 'styled-components'
import { SECONDARY, white } from '../../shared/styles/colors'
import { NavLink } from 'react-router-dom'
import media from 'styled-media-query'
import { Button } from '@material-ui/core'

export const MenuBarWrapper = styled.aside`
  align-items: center;
  background: ${SECONDARY};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  position: fixed;
  width: 80px;

  ${media.lessThan('767px')`
    height: 48px;
    overflow: hidden;
    flex-direction: row;
    position: relative;
    width: 100%;
    justify-content: center;
  `}
`

export const MenuButton = styled(Button)`
  &.MuiButton-root {
    position: absolute;
    color: white;
    left: 0;
  }
`

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px 0;

  svg {
    width: 50px;
    height: 36px;
  }
`

export const MenuBarGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const MenuBarLink = styled(NavLink)`
  display: block;
  margin-bottom: 20px;
  ${({ active }) =>
    active &&
    css`
      width: 54px;
      height: 54px;
      border-radius: 50%;
      border: 1px solid #0e3574;
      backdrop-filter: blur(30px);
      box-shadow: 0 -1px 5px 0 #133877;
      background-image: linear-gradient(134deg, #10183c 15%, #0e3574 86%);
    `}
`

export const MenuBarAvatar = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 0;
  cursor: pointer;
  margin-bottom: 20px;
  ${({ onBottom }) => onBottom && css`
    position: absolute;
    bottom: 20px;
    margin-bottom: unset;
  `}
`

export const Avatar = styled.img`
  width: 48px;
  height: 48px;
`

export const AvatarName = styled.p`
  color: ${white};
  font-size: 14px;
  font-weight: 600;
`

export const MenuBarItem = styled.span`
  color: #8899a6;
  cursor: pointer;
  height: 100%;
  padding: 9px;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 15px;
`
