import styled, { css } from 'styled-components'
import {
  IconButton as MuiIconButton,
  AppBar,
  Typography
} from '@material-ui/core'
import { SECONDARY, GREY, PRIMARY } from '../../../shared/styles/colors'
import { flexBox } from '../../../shared/styles/mixins'

export const Wrapper = styled(AppBar)`
  top: 0;
  position: absolute !important;
  ${({ nomargin }) =>
    !nomargin &&
    css`
      margin-bottom: 24px;
    `};
`

export const TopHeader = styled.section`
  background-color: ${SECONDARY};
  height: 46px;
  ${flexBox()};
`

export const IconButton = styled(MuiIconButton)`
  &.MuiIconButton-edgeStart {
    padding: 4px;
    z-index: 11;
  }
`

export const TitleContainer = styled.section`
  width: 100%;
  position: absolute;
  top: 16px;
  left: 0;
  right: 0;
  z-index: 10;

  @media (min-width: 600px) {
    font-size: 25px;
    top: 9px;
  }
`

export const Title = styled(Typography)`
  &.MuiTypography-h6 {
    font-size: 18px;

    @media (min-width: 600px) {
      font-size: 25px;
    }
  }
`

export const StepCaption = styled(Typography)`
  &.MuiTypography-caption {
    font-size: 10px;

    @media (min-width: 600px) {
      font-size: 12px;
    }
  }
`

export const Steps = styled.div`
  padding: 12px 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  margin: auto;
`

export const Step = styled.div`
  background-color: ${({ checked }) => (checked ? PRIMARY : GREY)};
  height: 2px;
  width: 21vw;
  margin: 0 3.5px;
  box-sizing: border-box;
`
