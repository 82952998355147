import { parseDateToISO } from "../shared/helpers/parseDate";

export default class User {
  constructor(obj = {}) {
    const { cpf, name, dateOfBirth, phoneNumber, email, homeCompanyCode } = obj;

    this.name = name;
    this.phoneNumber = phoneNumber;
    this.cpf = cpf;
    this.dateOfBirth = dateOfBirth && parseDateToISO(dateOfBirth);
    this.email = email;
    this.homeCompanyCode = homeCompanyCode;
  }
}