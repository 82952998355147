import React, { memo, useRef, useState } from 'react';
import { FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import * as Styled from './styled';

const Select = ({ label, name, onChange, options, defaultValue, value, error, helperText }) => {
  const [labelWidth, setLabelWidth] = useState(0);
  const inputLabel = useRef(null);

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <FormControl fullWidth variant="outlined" error={error}>
      <InputLabel
        ref={inputLabel}
        id={`${name}SelectLabel`
        }
      >
        {label}
      </InputLabel>
      <Styled.Select
        native
        labelId={`${name}SelectLabel`}
        id={`${name}Select`}
        defaultValue={defaultValue || value}
        onChange={onChange}
        labelWidth={labelWidth}
        name={name}
      >
        <option value="" />
        {options.map(({ text, value }) => (
          <option value={value} key={text}>{text}</option>
        ))}
      </Styled.Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
};

Select.defaultProps = {
  value: ''
};

export default memo(Select);