import styled from 'styled-components';
import { Link } from '@material-ui/core';
import { LINK } from '../../shared/styles/colors';

export const Wrapper = styled(Link)`
  &.MuiLink-button {
    overflow: visible;
    flex: 1 1;
    text-overflow: initial;
    color: ${LINK};
    text-decoration: underline;
  }
`;
