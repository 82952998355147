import React, { memo, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import useDebounce from '../../hooks/useDebounce';
import SearchIcon from '@material-ui/icons/Search';

const AutocompleteObject = ({ getOptionLabel, setText, label, onChange, renderOption, setOptions, autocompleteClass }) => {
  const [inputValue, setInputValue] = React.useState('');
  const debouncedSearchTerm = useDebounce(inputValue, 500);

  const handleChange = event => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    let active = true;
    if (active && debouncedSearchTerm.length >= 3) {
      setText(debouncedSearchTerm)
    } else if(active && !debouncedSearchTerm.length) {
      setText('')
    }

    return () => {
      active = false
    }
  }, [debouncedSearchTerm])

  return (
    <TextField
      className={autocompleteClass}
      label={label}
      variant="outlined"
      fullWidth
      onChange={handleChange}
      autoComplete="off"
      InputProps={{
        endAdornment: <SearchIcon />,
      }}
    />
  );
}

AutocompleteObject.defaultProps = {
  getOptionLabel: option => (typeof option === 'string' ? option : option.text),
  renderOption: option => <option value={typeof option.value === 'string' ? option.value : JSON.stringify(option.value)}>{option.text}</option>
};

export default memo(AutocompleteObject);