import React, { Suspense, useState } from 'react'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import CheckIcon from '@material-ui/icons/Check'
import { Button, Snackbar, Fade, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import * as Styled from './Login.style'
import LogoWDental from '../../assets/svgs/logo-wdental.svg'
import { ReactSVG } from 'react-svg'
import LoginForm from '../../components/LoginForm'
import { ForgetPasswordForm } from '../../components/ForgetPasswordForm'
import FirstLoginForm from '../../components/Forms/FirstLoginForm'
import { SECONDARY } from '../../shared/styles/colors'
import { login } from '../../shared/services/login'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import Alert from '../../components/Alert'
import { useDispatch } from 'react-redux'
import { get } from 'lodash'

const useStyles = makeStyles({
  root: {
    fontSize: 32,
    marginTop: 60,
    marginBottom: 25,
    color: SECONDARY
  },
  forgetPassword: {
    fontSize: 32,
    marginTop: 0,
    marginBottom: 25,
    color: SECONDARY
  },
  label: {
    color: '#696969',
    fontSize: 16,
    marginBottom: 24
  },
  buttonBack: {
    borderRadius: 50,
    padding: 5,
    minWidth: 34,
    position: 'absolute',
    top: 97,
    left: 97
  },
  buttonSignup: {
    backgroundColor: 'white',
    fontWeight: 600,
    marginTop: 16,
    borderRadius: 20,
    textTransform: 'unset'
  },
  logo: {
    '@media(max-width: 767px)': {
      marginTop: 80,
    }
  }
})

export default function Login() {
  const history = useHistory()
  const classes = useStyles()
  const [isLoginSection, setIsLoginSection] = useState(true)
  const [needUpdate, setNeedUpdate] = useState(false)
  const [data, setData] = useState({})
  const [snackbarOpened, setSnackbarOpened] = useState(false)
  const [alert, setAlert] = useState({ severity: 'error', opened: false, msg: '' })
  const dispatch = useDispatch()

  const updatePassword = async (values) => {
    try {
      const result = await login({...values, ...data})
      dispatch({ type: 'USER_DATA', data: { ...result} })
      history.go('/dashboard')
    } catch(e) {
      setAlert({ severity: 'error', opened: true, msg: get(e, 'messages[0].text', 'Ocorreu um erro inesperado, favor tente mais tarde!') })
    }
  }

  return (
    <Styled.Login>
      <Suspense fallback={'loading...'}>
        <Styled.Container>
          <ReactSVG className={classes.logo} src={LogoWDental} />
          {isLoginSection ? (
            <>
              <Typography className={classes.root} variant='h6' gutterBottom>
                Área Restrita
              </Typography>
              {needUpdate ? <FirstLoginForm {...data} onSubmit={updatePassword} /> : <LoginForm setData={setData} setNeedUpdate={setNeedUpdate} setAlert={setAlert}/>}
              <Styled.ForgetPassword
                onClick={() => {
                  setIsLoginSection(false)
                }}
              >
                Esqueci minha senha
              </Styled.ForgetPassword>
              <Styled.BeASellerChampion>
                {' '}
                Torne-se um campeão de vendas!{' '}
              </Styled.BeASellerChampion>
              <Button
                component={Link}
                to='/cadastrar'
                fullWidth
                variant='outlined'
                className={classes.buttonSignup}
              >
                {' '}
                Cadastre-se{' '}
              </Button>
            </>
          ) : (
              <>
                <Button
                  onClick={() => {
                    setIsLoginSection(true)
                  }}
                  className={classes.buttonBack}
                  variant='outlined'
                >
                  <ChevronLeftIcon />
                </Button>
                <Typography
                  className={classes.forgetPassword}
                  variant='h6'
                  gutterBottom
                >
                  Esqueceu sua Senha?
              </Typography>
                <Typography
                  className={classes.label}
                  variant='body1'
                  gutterBottom
                >
                  Insira abaixo seu CPF/CNPJ cadastrado para reconfigurar sua senha
              </Typography>
                <ForgetPasswordForm setIsLoginSection={setIsLoginSection} setSnackbarOpened={setSnackbarOpened} />
              </>
            )}
        </Styled.Container>
      </Suspense>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpened}
        onClose={() => {
          setSnackbarOpened(false)
        }}
        TransitionComponent={Fade}
        message='Foi enviado um link de recuperação de senha para o seu email cadastrado!'
        autoHideDuration={5000}
        action={<CheckIcon fontSize='default' />}
      />
      <Alert openState={alert.opened} severity={alert.severity}>
        {alert.msg}
      </Alert>
    </Styled.Login>
  )
}
