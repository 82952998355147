import { isCPF } from "brazilian-values";
import { verifyCpf } from "../../shared/services/cpf";

const useCpf = (keys = [
  'dateOfBirth',
  'name',
  'motherName'
]) => {
  const setInfo = setFieldValue => (payload = {}) => {
    for (let key of keys) {
      const value = payload[key];
      setFieldValue(key, value);
    }
    setFieldValue('dateOfBirth', payload.birthDate);
  }

  const consultCpf = ({ setFieldValue, handleChange, setHasBirthDateField, setIsLoading }) => async (event) => {
    const handleChangeInfo = setInfo(setFieldValue);
    const { value } = event.target;
    const handleLoading = setIsLoading || (() => {});
    handleChangeInfo();
    handleChange(event);
    let hasBirthDateField = false;
    if (isCPF(value)) {
    try {
        handleLoading(1);
        const response = await verifyCpf(value);
        handleChangeInfo(response);
      } catch (error) {
        hasBirthDateField = true;
        handleChangeInfo();
      } finally {
        if (setHasBirthDateField)
          setHasBirthDateField(hasBirthDateField);
        handleLoading(0);
      }
    }
  }

  return { consultCpf };
}

export default useCpf;