import styled, {css} from 'styled-components'
import { Drawer as MuiDrawer } from '@material-ui/core'
import media from 'styled-media-query'
import CloseIcon from '@material-ui/icons/Close'
import { NavLink } from "react-router-dom";

export const Detail = styled.section`
  .drawer {
    .MuiPaper-root {
      background-color: #121A3D;
    }
  }
`

export const Drawer = styled(MuiDrawer)`
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, .95)
  }
  .MuiDrawer-paper {
    background-color: #121A3D;
    flex-direction: column;
    ${media.lessThan('767px')`
      width: 80%;
    `}
  }
`

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 27px 0 79px;
  
  svg {
    width: auto;
    height: 58px;
  }
`;

export const Close = styled(CloseIcon)`
  position: fixed;
  color: white;
  font-size: 27px;
  top: 23px;
  right: 23px;
`

//TODO separar todos abaixo em um outro component depois

export const MenuBarGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 20px;
`;

export const MenuBarLink = styled(NavLink)`
  display: block;
  ${({ active }) =>
    active &&
    css`
      width: 54px;
      height: 54px;
      border-radius: 50%;
      border: 1px solid #0e3574;
      backdrop-filter: blur(30px);
      box-shadow: 0 -1px 5px 0 #133877;
      background-image: linear-gradient(134deg, #10183c 15%, #0e3574 86%);
    `}
`;

export const MenuBarItem = styled.span`
  color: white;
  cursor: pointer;
  height: 100%;
  padding: 9px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  svg {
    margin: 0 32px 0 6px;
  }
`;