import React, { Suspense, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import * as Styled from './Login.style'
import LogoWDental from '../../assets/svgs/logo-wdental.svg'
import { ReactSVG } from 'react-svg'
import UpdatePasswordForm from '../../components/Forms/UpdatePasswordForm'
import { SECONDARY } from '../../shared/styles/colors'
import { getParam } from '../../shared/utils/getParams/getParams'
import { changePassword } from '../../shared/services/user'

const useStyles = makeStyles({
  root: {
    fontSize: 32,
    marginTop: 60,
    marginBottom: 25,
    color: SECONDARY
  },
  forgetPassword: {
    fontSize: 32,
    marginTop: 0,
    marginBottom: 25,
    color: SECONDARY
  },
  label: {
    color: '#696969',
    fontSize: 16,
    marginBottom: 24
  },
  buttonBack: {
    borderRadius: 50,
    padding: 5,
    minWidth: 34,
    position: 'absolute',
    top: 97,
    left: 97
  },
  buttonSignup: {
    backgroundColor: 'white',
    fontWeight: 600,
    marginTop: 16,
    borderRadius: 20,
    textTransform: 'unset'
  },
  logo: {
    '@media(max-width: 767px)': {
      marginTop: 80,
    }
  }
})

export default function ChangePasswordPage () {
  const classes = useStyles()
  const [recoverPasswordCode, setRecoverPasswordCode] = useState('')

  useEffect(() => {
    setRecoverPasswordCode(getParam('recoverPasswordCode'))
  }, [])

  const onSubmit = (values) => {
    const data  = {...values, recoverPasswordCode}
    changePassword(data)
  }

  return (
    <Styled.Login>
      <Suspense fallback={'loading...'}>
        <Styled.Container>
          <ReactSVG className={classes.logo} src={LogoWDental} />
          <UpdatePasswordForm onSubmit={onSubmit}/>
        </Styled.Container>
      </Suspense>
    </Styled.Login>
  )
}
