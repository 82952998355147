import React, { memo } from 'react'
import * as Styled from './style'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Sidemenu from '../../components/Sidemenu';

const Layout = ({ children, Title, hideFooter }) => {

  return (
    <Styled.Wrapper>
      <Sidemenu />
      <Styled.Container>
        <Header>
          {Title}
        </Header>
        <Styled.Main hideFooter={Number(hideFooter)}>
          {children}
        </Styled.Main>
        {!hideFooter && <Footer />}
      </Styled.Container >
    </Styled.Wrapper>
  )
}

export default memo(Layout)