const getTimestamp = ({ day, month, year }) => Date.parse(`${year}/${month}/${day}`);

export const parseDateToBR = date => {
  if (date instanceof Date) {
    return date.toLocaleDateString('pt-br');
  }

  if (typeof date === 'string') {
    const [year, month, day] = date.split('-');
    const timestamp = getTimestamp({ day, month, year });
    return new Date(timestamp).toLocaleDateString('pt-br');
  }

  return '';
}

export const parseDateToISO = date => {
  if (date instanceof Date) {
    return date.toISOString();
  }

  if ((/\d{8}/g).test(date)) {
    return new Date(
      date.substr(4, 8),
      date.substr(2, 2),
      date.substr(0, 2),
    ).toISOString();
  }

  if (typeof date === 'string') {
    let timestamp;

    if (date.includes('/')) {
      const [day, month, year] = date.substring(0, 10).split('/');
      timestamp = getTimestamp({ day, month, year });
    }

    if (date.includes('-')) {
      const [year, month, day] = date.substring(0, 10).split('-');
      timestamp = getTimestamp({ day, month, year });
    }

    return timestamp ? new Date(timestamp).toISOString() : '';
  }

  return '';
}