import React, { memo } from 'react'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import * as Styled from './styles'

function SidePage ({ opened, setOpened, title, children, showBlueLine, mainStyle }) {

  const toggleDrawer = value => {
    setOpened(prevState => value || !prevState)
  }

  return (
    <Styled.Detail>
      <Styled.Drawer
        paper={{ flexFlow: 'row' }}
        anchor={'right'}
        open={opened}
        onClose={() => {
          toggleDrawer(false)
        }}
      >
        <Styled.DetailWrapper>
          <Styled.Header>
            {title}
            <IconButton
              aria-label='fechar histórico de transações'
              onClick={() => toggleDrawer()}
            >
              <CloseIcon style={{ fontSize: '1.5em' }} />
            </IconButton>
          </Styled.Header>
          <Styled.DetailMain
            style={{ ...mainStyle, borderTop: showBlueLine ? `12px solid #121a3d` : null }}
            container
            spacing={1}
          >
            {children}
          </Styled.DetailMain>
        </Styled.DetailWrapper>
      </Styled.Drawer>
    </Styled.Detail>
  )
}

export default memo(SidePage)
