import React, { memo } from 'react';
import { Container } from '@material-ui/core';
import * as Styled from './styled';

const ContrastedContainer = ({ children, margin, style }) => (
  <Styled.Wrapper margin={margin} style={style}>
    <Container maxWidth="sm">
      {children}
    </Container>
  </Styled.Wrapper>
)

ContrastedContainer.defaultProps = {
  inner: false
};

export default memo(ContrastedContainer);