import React, { Suspense, useState } from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import * as Styled from './Login.style'
import LogoWDental from '../../assets/svgs/logo-wdental.svg'
import { ReactSVG } from 'react-svg'
import PasswordForm from '../../components/Forms/PasswordForm'
import { useHistory } from 'react-router-dom'
import Alert from '../../components/Alert'
import { SECONDARY } from '../../shared/styles/colors'
import PasswordChange from '../../models/PasswordChange'
import { get } from 'lodash'
import getParam from '../../shared/utils/getParams'
import { changePassword } from '../../shared/services/login'

const useStyles = makeStyles({
  root: {
    fontSize: 32,
    marginTop: 60,
    marginBottom: 25,
    color: SECONDARY
  },
  logo: {
    '@media(max-width: 767px)': {
      marginTop: 80,
    }
  }
})

export default function RecoverPassword () {
  const classes = useStyles()
  const history = useHistory()

  const [snackbarSuccessOpened, setSnackbarSuccessOpened] = useState(false)
  const [snackbarErrorOpened, setSnackbarErrorOpened] = useState(false)
  const [snackbarErrorMessage, setSnackbarErrorMessage] = useState('')

  const handleSubmit = (values, formikProps) => {
    if (values) {
      const body = new PasswordChange({
        ...values,
        recoverPasswordCode: getParam('recoverPasswordCode')
      })
      changePassword(body)
        .then(_ => {
          setSnackbarSuccessOpened(true)
          setTimeout(() => {
            history.push('/login')
          }, 5500)
        })
        .catch(e => {
          setSnackbarErrorMessage(
            get(
              e,
              'messages[0].text',
              'Ocorreu um erro! Favor tente mais tarde!'
            )
          )
          setSnackbarErrorOpened(true)
        })
    }
  }

  return (
    <Styled.Login>
      <Suspense fallback={'loading...'}>
        <Styled.Container>
          <ReactSVG className={classes.logo} src={LogoWDental} />
          <>
            <Typography className={classes.root} variant='h6' gutterBottom>
              Trocar Senha
            </Typography>
            <PasswordForm
              buttonTitle='Confirmar troca'
              hideTitle={true}
              onSubmit={handleSubmit}
            />
          </>
        </Styled.Container>
      </Suspense>

      <Alert openState={snackbarErrorOpened} severity='error'>
        {snackbarErrorMessage}
      </Alert>

      <Alert openState={snackbarSuccessOpened} severity='success'>
        Senha alterada com sucesso!
      </Alert>
    </Styled.Login>
  )
}
