import { object, string } from 'yup';
import { isCNPJ, isPhone } from 'brazilian-values';

export default object().shape({
  cnpj: string().min(14, 'Informe um cnpj válido').test('is-valid-cnpj', 'Informe um cnpj válido', isCNPJ).required('Informe o CNPJ'),
  email: string().email('Informe um e-mail válido').required('Informe o e-mail'),
  openingDate: string().required(),
  phoneNumber: string().required().test('is-valid-phone', 'Informe um telefone válido', isPhone).required('Informe o CNPJ'),
  businessName: string().required(),
});
