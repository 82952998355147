import React, { useEffect } from 'react'
import { ReactSVG } from 'react-svg'
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom'
import Next from '../../../assets/svgs/chevron-right-black.svg'
import BusinessIcon from '@material-ui/icons/Business'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import {
  dispatchRouteChanged,
} from '../../../shared/services/gtmEventDispatcher'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
  },
  card: {
    backgroundColor: '#fff',
    cursor: 'pointer',
    width: '100%',
    maxWidth: 500,
    marginBottom: 20,
    position: 'relative',
    display: 'grid',
    padding: 24,
    gridGap: '2px',
    gridTemplateColumns: '50px 200px',
    gridTemplateRows: '48px 48px',
    alignItems: 'flex-end',
    gridTemplateAreas: `
    "icon title"
    ". description"
    `,
  },
  icon: {
    gridArea: 'icon',
    fontSize: '3rem',
  },
  title: {
    gridArea: 'title',
  },
  description: {
    gridArea: 'description',
    alignSelf: 'flex-start',
  },
  iconChoose: {
    gridArea: 'iconChoose',
    position: 'absolute',
    top: 42,
    right: 24,
  },
}));

const OPTIONS = [
  {
    Icon: BusinessIcon,
    title: 'Empresarial',
    description: 'Cadastro de pessoas júridicas',
    goTo: '/cadastrar/empresa',
  },
  {
    Icon: PersonOutlineIcon,
    title: 'Individual',
    description: 'Cadastro de pessoas físicas',
    goTo: '/cadastrar/dados-pessoais',
  },
]

const ChoicePage = () => {
  const classes = useStyles()

  useEffect(() => {
    dispatchRouteChanged({
      pageCategory: 'vendedor',
      urlpasso: window.location.pathname
    })
  }, [])

  return (
    <section className={classes.root}>
      {OPTIONS.map(({ Icon, title, description, goTo }) => (
        <Link className={classes.card} key={title} to={goTo}>
          <Icon className={classes.icon} />
          <h2 className={classes.title}>{title}</h2>
          <p className={classes.description}>{description}</p>
          <ReactSVG src={Next} className={classes.iconChoose} />
        </Link>
      ))}
    </section>
  )
}

export default ChoicePage
