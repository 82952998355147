import { useCallback, useState } from 'react';

const useDialog = (initialState = false) => {
  const [open, setOpen] = useState(initialState);

  const handleClose = useCallback(() => setOpen(false), []);
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleToggle = useCallback(() => setOpen(prevState => !prevState), []);

  return {
    handleClose,
    handleOpen,
    handleToggle,
    open
  }
}

export default useDialog;