import React, { memo, useState } from 'react'
import { Form, Formik } from 'formik'
import { Button, Grid, TextField } from '@material-ui/core'
import FormField from '../../FormField'
import * as Styled from './styled'
import validationSchema from './validationSchema'
import { handleBlurValidation } from '../../../shared/services/gtmEventDispatcher'
import { useStyles } from '../muiStyle'
import { ReactSVG } from 'react-svg'
import CheckPassRules from '../../../assets/svgs/check-pass-rules.svg'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import VisibilityIcon from '@material-ui/icons/Visibility'

const PasswordForm = ({ onSubmit, onClose, buttonTitle, hideTitle }) => {
  const EyeIcon = ({ state, updateState }) =>
    !state ? (
      <VisibilityOffIcon
        className={classes.icon}
        onClick={() => {
          updateState(prev => !prev)
        }}
      />
    ) : (
      <VisibilityIcon
        className={classes.icon}
        onClick={() => {
          updateState(prev => !prev)
        }}
      />
    )

  const handleFocus = ({ setFieldValue, fieldName }) => () => {
    setFieldValue('focused', fieldName)
    window.scrollTo(0, 0)
  }
  const classes = useStyles()
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)
  return (
    <Formik
      initialValues={{
        password: '',
        passwordConfirmation: ''
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnChange
    >
      {({
        handleBlur,
        values,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        dirty,
        isValid,
        ...formikProps
      }) => (
        <Form>
          {!hideTitle && <Styled.Title>Digite sua senha</Styled.Title>}
          <Styled.FormSection>
            <Grid container spacing={3}>
              <Grid
                style={{ margin: '0 auto', position: 'relative' }}
                item
                xs={12}
                sm={12}
              >
                <FormField
                  fullWidth
                  variant='outlined'
                  label='Digite a senha'
                  name='password'
                  hideCheckIcon={true}
                  type={passwordVisible ? 'text' : 'password'}
                  component={TextField}
                  onFocus={handleFocus({
                    setFieldValue,
                    fieldName: 'password'
                  })}
                  onBlur={() => {
                    handleBlurValidation({
                      errors: formikProps.errors,
                      fieldName: 'password'
                    })
                    handleBlur({ target: { name: 'password' } })
                  }}
                />
                <EyeIcon
                  state={passwordVisible}
                  updateState={setPasswordVisible}
                />
              </Grid>
              <Grid
                style={{ margin: '0 auto', position: 'relative' }}
                item
                xs={12}
                sm={12}
              >
                <FormField
                  fullWidth
                  hideCheckIcon={true}
                  variant='outlined'
                  label='Confirme a senha'
                  name='passwordConfirmation'
                  type={confirmPasswordVisible ? 'text' : 'password'}
                  component={TextField}
                  onFocus={handleFocus({
                    setFieldValue,
                    fieldName: 'passwordConfirmation'
                  })}
                  onBlur={() => {
                    handleBlurValidation({
                      errors: formikProps.errors,
                      fieldName: 'passwordConfirmation'
                    })
                    handleBlur({ target: { name: 'passwordConfirmation' } })
                  }}
                />
                <EyeIcon
                  state={confirmPasswordVisible}
                  updateState={setConfirmPasswordVisible}
                />
              </Grid>
              <Grid item xs={12} sm={12} align='flex-start'>
                <Styled.Rules
                  isInvalid={
                    !values.password ||
                    formikProps.errors.password ===
                      'A deve ter no mínimo 8 caracteres'
                  }
                >
                  <ReactSVG src={CheckPassRules} style={{ marginRight: 4 }} />
                  Mínimo de 8 caracteres
                </Styled.Rules>
                <Styled.Rules
                  isInvalid={
                    !values.password ||
                    formikProps.errors.password ===
                      'A deve ter no máximo 15 caracteres'
                  }
                >
                  <ReactSVG src={CheckPassRules} style={{ marginRight: 4 }} />
                  Máximo de 15 caracteres
                </Styled.Rules>
                <Styled.Rules
                  isInvalid={
                    !values.password ||
                    !values.passwordConfirmation ||
                    values.password !== values.passwordConfirmation
                  }
                >
                  <ReactSVG src={CheckPassRules} style={{ marginRight: 4 }} />
                  As senhas devem ser iguas
                </Styled.Rules>
              </Grid>
              <Grid item xs={12} align='center'>
                <Button
                  fullWidth
                  type='submit'
                  className={classes.root}
                  variant='contained'
                  disabled={isSubmitting || !dirty || !isValid}
                  color='primary'
                >
                  {buttonTitle || 'Finalizar'}
                </Button>
              </Grid>
            </Grid>
          </Styled.FormSection>
        </Form>
      )}
    </Formik>
  )
}

export default memo(PasswordForm)
