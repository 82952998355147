import { object, string } from 'yup';
import { isCPF, isPhone } from 'brazilian-values';

export default object().shape({
  cpf: string().min(11, 'Informe um cpf válido').test('is-valid-cpf', 'Informe um cpf válido', isCPF).required('Informe o CPF'),
  phoneNumber: string().min(11, 'Informe um telefone válido').test('is-valid-phone', 'Informe um telefone válido', isPhone).required('Informe o telefone'),
  email: string().email('Informe um e-mail válido').required('Informe o e-mail'),
  name: string().required(),
  dateOfBirth: string().required(),
  motherName: string().required()
});