import { getLink } from '../../shared/services/linkSelfService'

const useSelfServiceRedirect = ({ code, showFeedBack, showFeedBackError }) => {
  const redirectToSelfService = async () => {
    try {
      const { link } = await getLink(code)
      if(showFeedBack) { showFeedBack(true) }
      window.open(link, '_blank')
    } catch(e) {
      return showFeedBackError ? showFeedBackError(true) : e
    }
  }
  return { redirectToSelfService };
}

export default useSelfServiceRedirect;
