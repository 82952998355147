import React from 'react'
import { Redirect } from 'react-router-dom'

import DashboardPage from '../pages/Dashboard'
import BranchOfficePage from '../pages/BranchOffice'
import Employees from '../pages/Employees'
import UserData from '../pages/Signup/UserData'
import CompanyData from '../pages/Signup/CompanyData'
import AddressPage from '../pages/Signup/Address'
import Files from '../pages/Signup/Files'
import Password from '../pages/Signup/Password'
import Choice from '../pages/Signup/Choice'
import Congratulations from '../pages/Signup/Congratulations'
import ProfilePage from '../pages/Profile'
import ChangePasswordPage from '../pages/ChangePassword'

export const PRIVATE_ROUTES = [
  { path: '/dashboard', component: DashboardPage },
  { path: '/filiais', component: BranchOfficePage },
  { path: '/funcionarios', component: Employees },
  { path: '/perfil', component: ProfilePage },
  { path: '/trocar-senha', component: ChangePasswordPage }
]

export const SIGNUP_ROUTES = [
  {
    path: '/cadastrar/dados-pessoais',
    component: UserData,
    step: 2,
    title: 'Olá! Vamos começar?'
  },
  {
    path: '/cadastrar/endereco',
    component: AddressPage,
    step: 3,
    title: 'Onde você mora?'
  },
  {
    path: '/cadastrar/anexar-documentos',
    component: Files,
    step: 4,
    title: 'Anexar documentos'
  },
  {
    path: '/cadastrar/senha',
    component: Password,
    step: 5,
    title: 'Cadastre sua senha'
  },
  {
    path: '/cadastrar/sucesso',
    component: Congratulations,
    step: 0,
    title: 'Cadastro concluído com sucesso!'
  },
  {
    path: '/cadastrar/empresa',
    component: CompanyData,
    maxSteps: 3,
    step: 2,
    title: 'Dados da empresa'
  },
  {
    path: '/cadastrar/senha-empresa',
    component: Password,
    step: 5,
    title: 'Cadastre sua senha'
  },
  { path: '/cadastrar', component: Choice, step: 1, title: 'Tipo de cadastro' },
  {
    path: '/cadastro',
    component: () => (
      <Redirect to='/cadastrar' />
    ),
    step: 1,
    title: 'Tipo de cadastro'
  }
]
