import styled, { css } from 'styled-components'
import {
  DialogTitle as MuiDialogTitle,
  Dialog,
  Typography
} from '@material-ui/core'
import { SECONDARY } from '../../shared/styles/colors';

export const Title = styled(Typography)`
  &.MuiTypography-h5 {
    margin-bottom: 24px;
  }
`

export const DialogTitle = styled(MuiDialogTitle)`
  &.MuiDialogTitle-root {
    padding-bottom: 0;
  }
`

export const WDialog = styled(Dialog)`
  ${({ halfmodal }) =>
    halfmodal &&
    css`
      @media (min-width: 540px) {
        .MuiDialog-paperFullWidth {
          width: 100%;
          max-width: 100%;
          position: fixed;
          bottom: 0;
          margin: 0;
        }
        .MuiDialogTitle-root {
            background-color: ${SECONDARY};
        }
      }
    `}
`
