import React, { useState, memo, useEffect } from 'react'
import TabPanel from '../TabPanel'
import { Tabs, Tab, AppBar, useTheme, useMediaQuery } from '@material-ui/core'
import Plan from '../Plan'
import { getFamily, getCompany } from '../../shared/services/plans'
import { makeStyles } from '@material-ui/core'
import SwitchButton from '../SwitchButton'

const useStyles = makeStyles(() => ({
  toggler: {
    color: `#121a3d`,
    textAlign: `center`,
    marginBottom: 20,
    '@media (max-width: 600px)': {
      display: `flex`,
      justifyContent: `space-between`,
      padding: `0 30px`,
      fontSize: `1rem`,
      margin: '30px 0 0',
    }
  }
}))

const PlansSection = () => {
  const theme = useTheme()
  const classes = useStyles()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const [isShowingOrthodontics, setIsShowingOrthodontics] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0)
  const [familyPlans, setFamilyPlans] = useState([])
  const [familyPlansOrtho, setFamilyPlansOrtho] = useState([])
  const [companyPlans, setCompanyPlans] = useState([])
  const [selectedFamilyPlans, setSelectedFamilyPlans] = useState([])

  useEffect(() => {
    Promise.all([getCompany(), getFamily()]).then(handlePlans)
  }, [])

  const handlePlans = ([company, family]) => {
    setCompanyPlans(
      () => company.group.find(({ hasOrthodontics }) => !hasOrthodontics).plans
    )
    setFamilyPlans(
      () => family.group.find(({ hasOrthodontics }) => !hasOrthodontics).plans
    )
    setSelectedFamilyPlans(
      () => family.group.find(({ hasOrthodontics }) => !hasOrthodontics).plans
    )
    setFamilyPlansOrtho(
      () => family.group.find(({ hasOrthodontics }) => !!hasOrthodontics).plans
    )
  }

  function tabsProps (index) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
      style: { width: isMobile ? '50%' : undefined }
    }
  }

  const toggleOrthodontics = (value) => {
    setIsShowingOrthodontics(value)
    setSelectedFamilyPlans(value ? familyPlansOrtho : familyPlans)
  }

  return (
    <section style={{ width: '100%' }}>
      <AppBar position='static'>
        <Tabs
          textColor='primary'
          indicatorColor='secondary'
          value={selectedTab}
          onChange={(e, i) => {
            setSelectedTab(i)
          }}
          aria-label='tabs com opção de pagamento'
        >
          <Tab label='Planos individuais' {...tabsProps(0)} />
          <Tab label='Planos Empresariais' {...tabsProps(1)} />
          </Tabs>
          {selectedTab === 0 && (
            <article className={classes.toggler}>
              {' '}
              Adicionar Ortodontia?{' '}
              <SwitchButton
                checked={isShowingOrthodontics}
                onChange={(o, val) => toggleOrthodontics(val)}
                name='checkOrthodonticsPlans'
                aria-label={`${
                  isShowingOrthodontics ? 'Des' : 'S'
                }elecionar planos com ortodontia`}
              />{' '}
            </article>
          )}
      </AppBar>
      <TabPanel value={selectedTab} index={0}>
        {selectedFamilyPlans.map((plan, i) => (
          <Plan {...plan} key={`planFamily${i}`} />
        ))}
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        {companyPlans.map((plan, i) => (
          <Plan {...plan} key={`planCompany${i}`} isCompany={true} />
        ))}
      </TabPanel>
    </section>
  )
}

export default memo(PlansSection)
