import styled from 'styled-components'
import { flexBox } from '../../styles/mixins'
import loginbg from '../../assets/images/login_bg.png'
import loginbgmobile from '../../assets/images/login_bg_mobile.jpeg'

export const Login = styled.div`
  ${flexBox()};
  background: url(${loginbg});
  background-color: #e5e9ea;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  @media (max-width: 1024px) {
    background: url(${loginbgmobile});
    background-repeat: no-repeat;
    background-color: #e5e9ea;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
  }
`

export const Container = styled.section`
  border-top: 8px #121a3d solid;
  float: right;
  width: 560px;
  height: 743px;
  padding: 20px 103px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  ${flexBox('column')};

  @media (max-width: 1024px) {
    height: 100%;
    width: 100%;
    backdrop-filter: blur(4px);
    background: rgba(255, 255, 255, 0.4);
  }
  @media (max-width: 600px) {
    padding: 30px;
    overflow-y: auto;
  }
`

export const ForgetPassword = styled.a`
  text-decoration: underline;
  color: #0b54db;
  margin-top: 46px;
  cursor: pointer;
`

export const BeASellerChampion = styled.strong`
  font-size: 2rem;
  color: #121a3d;
  text-align: center;
  margin-top: 56px;
`
