import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { formatToPhone } from 'brazilian-values';
import MaskedTextField from '../MaskedTextField';

const getFormat = (phoneType = 'dynamic') => (value = '') => {
  let text = value.substring(0, 11);
  if (phoneType === 'residential') {
    text = value.substring(0, 10);
  }

  return formatToPhone(text);
}

const PhoneTextField = ({ phoneType, ...props }) => {
  const format = useMemo(() => getFormat(phoneType), [phoneType]);

  return (
    <MaskedTextField
      {...props}
      format={format}
      type="tel"
      customInput={TextField}
    />
  );
}

PhoneTextField.propTypes = {
  phoneType: PropTypes.oneOf(['cell', 'dynamic', 'residential']),
}

PhoneTextField.defaultProps = {
  phoneType: 'dynamic'
};

export default memo(PhoneTextField);