import { object, string } from 'yup'

const numberMessageMin = 'A deve ter no mínimo 8 caracteres'
const numberMessageMax = 'A deve ter no máximo 15 caracteres'

export default object().shape({
  identification: string().required('Favor preencher com sei CPFCNPJ'),
  password: string().required('Favor preencher senha atual'),
  newPassword: string()
    .required('Favor preencher nova senha')
    .min(8, numberMessageMin)
    .max(15, numberMessageMax),
  newPasswordConfirmation: string()
    .required('Favor preencher confirmação de nova senha')
    .min(8, numberMessageMin)
    .max(15, numberMessageMax)
})
