import React, { memo, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Container, Grid, Hidden, TextField } from '@material-ui/core'
import { Formik, Form } from 'formik'
import FormField from '../../FormField'
import DocumentTextField from '../../DocumentTextField'
import validationSchema from './validationSchema'
import CnpjInfos from '../../CnpjInfos'
import useCnpj from '../../../hooks/useCnpj'
import * as Styled from './styled'
import { handleBlurValidation } from '../../../shared/services/gtmEventDispatcher'
import { useStyles } from '../muiStyle'
import PhoneTextField from '../../PhoneTextField'

const CompanyForm = ({ onSubmit }) => {
  const { consultCnpj } = useCnpj()
  const classes = useStyles();
  const [ showPhoneField, setShowPhoneField ] = useState(false)
  const [ loadingCnpj, setLoadingCnpj ] = useState(0)
  const [title, label] = useMemo(
    () =>
      [
        'Me fale um pouco sobre a empresa.',
        {
          cnpj: 'CNPJ',
          email: 'E-mail',
        }
      ], []
  )

  return (
    <Formik
      initialValues={{
        cnpj: '',
        email: '',
        openingDate: '',
        businessName: '',
        phoneNumber: '',
        fantasyName: '',
        address: {},
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({
        values,
        handleChange,
        setFieldTouched,
        handleBlur,
        isSubmitting,
        dirty,
        isValid,
        setFieldValue,
        errors,
        setFieldError,
      }) => (
        <Form>
          <Container maxWidth='sm'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Styled.Title variant='h6'>{title}</Styled.Title>
              </Grid>

              <Grid item xs={12} style={{position: 'relative'}}>
                <FormField
                  fullWidth
                  name='cnpj'
                  label={label.cnpj}
                  variant='outlined'
                  loading={loadingCnpj}
                  onBlur={event => {
                    handleBlurValidation({
                      errors,
                      fieldName: 'cnpj',
                      extraValidationKeys: ['openingDate', 'businessName']
                    })
                    handleBlur(event)
                    setFieldTouched('cnpj', true)
                  }}
                  onChange={consultCnpj({ handleChange, setFieldValue, setFieldTouched, setShowPhoneField, setFieldError, setLoadingCnpj })}
                  documentType='cnpj'
                  component={DocumentTextField}
                />
              </Grid>
            </Grid>
          </Container>

          <CnpjInfos {...values} />

          <Container
            maxWidth='sm'
            style={{ marginTop: !Boolean(values.name) && '12px' }}
          >
            <Grid container spacing={3}>

              { showPhoneField &&
                <Grid item xs={12}>
                  <FormField
                    fullWidth
                    name='phoneNumber'
                    label='Telefone'
                    variant='outlined'
                    onBlur={ev => {
                      handleBlurValidation({
                        errors,
                        fieldName: 'phoneNumber'
                      })
                      handleBlur(ev)
                      setFieldTouched('phoneNumber', true)
                    }}
                    component={PhoneTextField}
                  />
                </Grid>
              }

              <Grid item xs={12}>
                <FormField
                  type='email'
                  fullWidth
                  name='email'
                  label={label.email}
                  variant='outlined'
                  component={TextField}
                />
              </Grid>

              <Hidden xsDown>
                <Grid item sm={3} />
              </Hidden>

              <Grid item xs={12} sm={12} align='center'>
                <Button
                  className={classes.root}
                  fullWidth
                  type='submit'
                  color='primary'
                  variant='contained'
                  disabled={
                    isSubmitting || !dirty || !isValid
                  }
                >
                  Próximo
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Form>
      )}
    </Formik>
  )
}

CompanyForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
}

export default memo(CompanyForm)
