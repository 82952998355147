import React, { memo } from 'react';
import * as Styled from './styled';
import { SECONDARY } from '../../shared/styles/colors';

const DataField = ({ color, label, text, display, textStyle }) => (
  <>
    {label && <Styled.Label display={display} variant="body2" component="span">{label}</Styled.Label>}
    {text && <Styled.Text style={textStyle} display={display} textcolor={color} variant="body2" component="pre">{text}</Styled.Text>}
  </>
);

DataField.defaultProps = {
  color: SECONDARY,
  display: 'block'
};

export default memo(DataField);