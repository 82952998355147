import React, { memo, useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Button } from '@material-ui/core'
import * as Styled from './styled'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'

const DragNDrop = ({ getBase64, hasMarginTop, setExtension, title, subtitle, dataId, base64Default }) => {
  const [imageBase64, setImageBase64] = useState(base64Default || '')

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader()
      if (file) {
        reader.onload = () => {
          const binaryStr = reader.result
          setExtension(file.name.substring((file.name.length -4)))
          getBase64(binaryStr)
          setImageBase64(binaryStr)
        }
        reader.readAsDataURL(file)
      }
    })
  }, [getBase64, setExtension])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false
  })

  return (
    <>
      <Styled.Header hasmargintop={hasMarginTop}>
        {title || 'Documento de Identificação'}
        <i> {subtitle || 'Identidade CPF ou Carteira de Habilitação (CNh)'} </i>
      </Styled.Header>

      <Styled.Wrapper bg={imageBase64} {...getRootProps()}>
        {!imageBase64 && (
          <>
            <input {...getInputProps()} data-id={dataId}/>
            <Styled.EmptyFlag>Nenhum documento adicionado</Styled.EmptyFlag>
            <Button
              style={{ padding: '16px 56px' }}
              variant='contained'
              color='primary'
            >
              Anexar Documento
            </Button>
          </>
        )}
        {imageBase64 && (
          <Button
            style={{
              padding: 18,
              position: 'absolute',
              bottom: 10,
              right: 10
            }}
            color='primary'
            variant='contained'
            onClick={() => {
              setImageBase64('')
            }}
          >
            <DeleteOutlineIcon />
          </Button>
        )}
      </Styled.Wrapper>
    </>
  )
}

export default memo(DragNDrop)
