import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Button, Container, Grid, Hidden, TextField } from '@material-ui/core'
import { Formik, Form } from 'formik'
import FormField from '../../FormField'
import PhoneTextField from '../../PhoneTextField'
import validationSchema from './validationSchema'
import { handleBlurValidation } from '../../../shared/services/gtmEventDispatcher'
import { useStyles } from '../muiStyle'

const ProfileForm = ({ onSubmit, phoneNumber, email }) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        phoneNumber,
        email,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({
        values,
        handleChange,
        setFieldTouched,
        handleBlur,
        isSubmitting,
        dirty,
        isValid,
        setFieldValue,
        errors
      }) => (
        <Form>
          <Container
            maxWidth='sm'
            style={{ marginTop: !Boolean(values.name) && '12px', marginLeft: 0 }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField
                  fullWidth
                  name='phoneNumber'
                  label='Telefone'
                  variant='outlined'
                  onBlur={ev => {
                    handleBlurValidation({
                      errors,
                      fieldName: 'phoneNumber'
                    })
                    handleBlur(ev)
                    setFieldTouched('phoneNumber', true)
                  }}
                  component={PhoneTextField}
                />
              </Grid>

              <Grid item xs={12}>
                <FormField
                  type='email'
                  fullWidth
                  name='email'
                  label='E-mail'
                  variant='outlined'
                  component={TextField}
                />
              </Grid>

              <Hidden xsDown>
                <Grid item sm={3} />
              </Hidden>

              <Grid item xs={12} sm={12} align='center'>
                <Button
                  className={classes.root}
                  fullWidth
                  type='submit'
                  color='primary'
                  variant='contained'
                  disabled={
                    isSubmitting || !dirty || !isValid
                  }
                >
                  Alterar Dados
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Form>
      )}
    </Formik>
  )
}

ProfileForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
}

export default memo(ProfileForm)
