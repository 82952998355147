import styled, { css } from 'styled-components'
import { flexBox, fontSize } from '../../shared/styles/mixins'

export const Header = styled.section`
  width: 500px;
  ${flexBox('column', 'center', 'flex-start')};
  position: relative;
  font-size: 1rem;
  color: #707070;
  @media (max-width: 600px) {
    width: 100%;
  }
  ${({hasmargintop}) => hasmargintop && css`
    margin-top: 26px;
  `}
  i {
    ${fontSize(11)}
  }
`

export const Wrapper = styled.section`
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  height: 176px;
  width: 500px;
  padding: 40px;
  ${flexBox('column', 'space-between')};
  position: relative;
  margin-top: 8px;
  @media (max-width: 600px) {
    height: 112px;
    width: 100%;
    padding: 16px;
  }
  ${({bg}) => bg && css`
    background-image: url("${bg}");
    background-repeat: no-repeat;
    background-size: cover;
  `}
`

export const EmptyFlag = styled.i`
  color: #707070;
  ${fontSize(11)};
`