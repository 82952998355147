import React, { useState, useEffect } from 'react'
import { makeStyles, Button, Tooltip } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import DeleteIcon from '@material-ui/icons/Delete'
import DialogConfirm from '../../components/DialogConfirm'
import * as Styled from './style'
import Layout from '../../components/Layout'
import { steel, ERROR } from '../../shared/styles/colors'
import Alert from '../../components/Alert'
import * as BranchesService from '../../shared/services/branches'
import SidePage from '../../components/SidePage'
import BranchOfficeCreateForm from '../../components/Forms/BranchOfficeCreateForm'
import { get } from 'lodash'
import AutocompleteObject from '../../components/AutocompleteObject';

const useStyle = makeStyles(theme => ({
  note: {
    color: '#505050',
    fontSize: 12,
    marginTop: 64,
    '@media(max-width: 767px)': {
      padding: '0 10%'
    }
  },
  headerSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  autocomplete: {
    width: '48%',
    maxWidth: 400,
    height: 55,
    backgroundColor: 'white',
  },
  autocompleteTextField: {
    '@media(max-width: 767px)': {
      padding: 0,
    }
  },
  subtitle: {
    color: '#505050',
    fontSize: 18,
    marginTop: 14,
    display: `flex`,
    '@media(max-width: 767px)': {
      fontSize: 10,
      marginTop: 2,
    }
  },
  card: {
    width: '100%',
    background: 'white',
    borderRadius: 8,
    display: 'flex',
    padding: '0 20px',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 120,
    marginBottom: 48
  },
  infos: {
    display: 'flex',
    width: '50%',
    justifyContent: 'space-between',
    color: steel,
    '@media(max-width: 767px)': {
      width: '100%',
      flexDirection: 'column',
    }
  },
  infosArticle: {
    '@media(max-width: 767px)': {
      marginTop: '20px',
    }
  },
  infoValue: {
    color: 'black',
    fontSize: 14,
    fontWeight: 600
  },
  floatingButton: {
    position: 'fixed',
    bottom: 30,
    right: 60,
    fontSize: 12,
    padding: '7px 34px',
    '@media(max-width: 767px)': {
      right: 10,
    }
  }
}))

export default function BranchOfficePage() {
  const classes = useStyle()
  const [branchesOffice, setBranchesOffice] = useState([])
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [text, setText] = useState('')
  const [createOpened, setCreateOpened] = useState(false)
  const [selectedBranchOffice, setSelectedBranchOffice] = useState()
  const [selectedPage, setSelectedPage] = useState(1)
  const [countBranchesOffice, setCountBranchesOffice] = useState(1)
  const [alert, setAlert] = useState({ severity: 'error', opened: false, msg: '' })
  const [countPages, setCountPages] = useState(1)

  useEffect(() => {
    updateInfos()
  }, [text, selectedPage])

  const handleResults = ({ count, branches }) => {
    setBranchesOffice(branches)
    setCountBranchesOffice(count.total)
    setCountPages(count.pages)
  }

  const updateInfos = () => {
    BranchesService.get({ text, selectedPage }).then(handleResults)
  }

  const handleAccept = () => {
    deleteBranchOffice()
  }
  const handleRefuse = () => {
    setConfirmOpen(false)
  }

  const createBranchOffice = async (values) => {
    try {
      await BranchesService.post(values)
      setCreateOpened(false)
      setAlert({ severity: 'success', opened: true, msg: 'Filial criada com sucesso' })
      updateInfos()
    } catch (e) {
      setAlert({ severity: 'error', opened: true, msg: get(e, 'messages[0].text', 'Ocorreu um erro inesperado favor tente mais tarde!') })
    }
  }

  const deleteBranchOffice = async () => {
    try {
      setConfirmOpen(false)
      await BranchesService.remove(selectedBranchOffice)
      updateInfos({ selectedPage: 1 }) 
      setCreateOpened(false)
      setAlert({ severity: 'success', opened: true, msg: 'Filial removida com sucesso' })
    } catch (e) {
      setAlert({ severity: 'error', opened: true, msg: get(e, 'messages[0].text', 'Ocorreu um erro inesperado favor tente mais tarde!') })
    }
  }

  return (
    <Layout
      hideFooter={true}
      Title={
        <section className={classes.headerSection}>
          <article>
            {' '}
          Filiais
          <article className={classes.subtitle}>
              {' '}
            Resultados: &nbsp; <strong>{countBranchesOffice}</strong> &nbsp;
            filiais
          </article>
          </article>
          <AutocompleteObject setOptions={handleResults} setText={setText} autocompleteClass={classes.autocomplete} textFieldClass={classes.autocompleteTextField} label='Buscar Filial' />
        </section>
      }
    >
      <Styled.Main>
        {branchesOffice.map(({ name, address, code },) => (
          <section className={classes.card} key={name}>
            <section className={classes.infos}>
              <article>
                <span>Nome</span>
                <p className={classes.infoValue}>{name}</p>
              </article>
              <article className={classes.infosArticle}>
                <span>Endereço</span>
                <p className={classes.infoValue}>
                  {address}
                </p>
              </article>
            </section>
            <Tooltip title='Apagar Filial' placement='top'>
              <Button
                onClick={() => {
                  setConfirmOpen(true)
                  setSelectedBranchOffice(code)
                }}
              >
                {' '}
                <DeleteIcon style={{ color: ERROR }} />{' '}
              </Button>
            </Tooltip>
          </section>
        ))}

        <Pagination count={countPages} color="secondary" onChange={(evt, selectedPage) => setSelectedPage(selectedPage)} />
      </Styled.Main>

      <SidePage
        title='Cadastrar Filial'
        opened={createOpened}
        setOpened={setCreateOpened}
        mainStyle={{ height: 'calc(100% + 50px)' }}
      >
        <BranchOfficeCreateForm onSubmit={createBranchOffice} />
      </SidePage>

      <DialogConfirm
        handleAccept={handleAccept}
        handleRefuse={handleRefuse}
        open={confirmOpen}
        title='Ação perigosa'
        description='Ao apagar essa filial, também irá apagar todos os funcionários e todo o histórico relacionados à ela'
      />

      <Button
        variant='contained'
        color='primary'
        className={classes.floatingButton}
        onClick={() => { setCreateOpened(true) }}
      >
        + Adicionar Filiais
      </Button>

      <Alert openState={alert.opened} severity={alert.severity}>
        {alert.msg}
      </Alert>
    </Layout>
  )
}
