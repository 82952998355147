import styled from 'styled-components';
import {Typography} from '@material-ui/core';
import { LABEL } from '../../shared/styles/colors';

export const Title = styled(Typography)`
  &.MuiTypography-h6 {
    font-size: 16px;
    font-weight: 500;
    color: ${LABEL};
    margin-top: 24px;
  }
`;