import 'ress';
import { createGlobalStyle } from 'styled-components';
import { bgcolor } from './colors';
import { fontSize, flexBox } from './mixins'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';

const GlobalStyle = createGlobalStyle`

  * {
    box-sizing: border-box;
  }

  html {
    overflow: hidden;
  }

  html, body, #root {
    ${fontSize(14)};
    font-family: 'Montserrat', sans-serif;
    background-color: ${bgcolor};
    height: 100%;
    width: 100%;
  }

  #root{
    ${flexBox('row', 'flex-start')};
  }

  img {
    display: block;
    max-width: 100%;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  button {
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }

  .MuiFormLabel-root {
    font-size: 14px !important;
  }

`;

export default GlobalStyle;