import React, { useState, useEffect } from 'react'
import { makeStyles, Button, Tooltip } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit';
import { Pagination } from '@material-ui/lab'
import Alert from '../../components/Alert'
import EmployeeEdit from '../../components/EmployeeEdit'
import EmployeeCreateForm from '../../components/Forms/EmployeeCreateForm'
import * as Styled from './style'
import Layout from '../../components/Layout'
import { steel } from '../../shared/styles/colors'
import { parseDateToISO } from '../../shared/helpers/parseDate'
import SidePage from '../../components/SidePage'
import * as EmployeeService from '../../shared/services/employee'
import { get } from 'lodash'
import AutocompleteObject from '../../components/AutocompleteObject';
import * as BranchesService from '../../shared/services/branches'


const useStyle = makeStyles(theme => ({
  note: {
    color: '#505050',
    fontSize: 12,
    marginTop: 64,
    '@media(max-width: 767px)': {
      padding: '0 10%'
    }
  },
  headerSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  autocomplete: {
    width: '48%',
    maxWidth: 400,
    height: 55,
    backgroundColor: 'white',
  },
  autocompleteTextField: {
    '@media(max-width: 767px)': {
      padding: 0,
    }
  },
  subtitle: {
    color: '#505050',
    fontSize: 18,
    marginTop: 14,
    display: `flex`,
    '@media(max-width: 767px)': {
      fontSize: 10,
      marginTop: 2,
    }
  },
  card: {
    width: '100%',
    background: 'white',
    borderRadius: 8,
    display: 'flex',
    padding: '0 20px',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 120,
    marginBottom: 48,
    flexWrap: 'wrap',
  },
  infos: {
    color: steel,
    marginRight: 20

  },
  infoValue: {
    color: 'black',
    fontSize: 14,
    fontWeight: 600
  },
  floatingButton: {
    position: 'fixed',
    bottom: 30,
    right: 60,
    fontSize: 12,
    padding: '7px 34px',
    '@media(max-width: 767px)': {
      right: 10,
    }
  }
}))


export default function Employees() {
  const classes = useStyle()
  const [employees, setEmpoloyees] = useState([])
  const [text, setText] = useState('')
  const [editOpened, setEditOpened] = useState(false)
  const [createOpened, setCreateOpened] = useState(false)
  const [alert, setAlert] = useState({ severity: 'error', opened: false, msg: '' })
  const [countPages, setCountPages] = useState(1)
  const [countEmployees, setCountEmployees] = useState(1)
  const [selectedEmployee, setSelectedEmployee] = useState()
  const [selectedPage, setSelectedPage] = useState(1)
  const [branchesOffice, setBranchesOffice] = useState([])

  useEffect(() => {
    BranchesService.get({ text: '', selectedPage: 1, take: 100 }).then(({ count, branches }) => {
      const employeeBranches = branches.map(({code, name}) => ({text: name, value: code}) )
      employeeBranches.unshift({text: 'Não possui filial', value: ' '})
      setBranchesOffice(employeeBranches)
    })
  }, [])

  useEffect(() => {
    updateInfos()
  }, [text, selectedPage])

  const handleResults = ({ count, employees }) => {
    setEmpoloyees(employees)
    setCountEmployees(count.total)
    setCountPages(count.pages)
  }

  const updateInfos = () => {
    EmployeeService.get({ text, selectedPage }).then(handleResults)
  }

  const createEmployees = async (values) => {
    try {
      const dataToSend = {...values, dateOfBirth: parseDateToISO(values.dateOfBirth) }
      if(dataToSend.branchCode === ' ') {
        delete dataToSend.branchCode
      }
      await EmployeeService.post(dataToSend)
      setCreateOpened(false)
      setAlert({ severity: 'success', opened: true, msg: 'Funcionário criado com sucesso' })
      updateInfos()
    } catch (e) {
      setAlert({ severity: 'error', opened: true, msg: get(e, 'messages[0].text', 'Ocorreu um erro inesperado favor tente mais tarde!') })
    }
  }

  const updateEmployees = async (code, values) => {
    const dataToSend = { ...selectedEmployee, ...values }
    try {
      if(dataToSend.branchCode === ' ') {
        delete dataToSend.branchCode
      }
      await EmployeeService.put(selectedEmployee.code, dataToSend)
      setCreateOpened(false)
      setAlert({ severity: 'success', opened: true, msg: 'Funcionário editado com sucesso' })
      updateInfos()
    } catch (e) {
      setAlert({ severity: 'error', opened: true, msg: get(e, 'messages[0].text', 'Ocorreu um erro inesperado favor tente mais tarde!') })
    }
  }


  return (
    <Layout
      hideFooter={true}
      Title={
        <section className={classes.headerSection}>
          <article>
            {' '}
          Funcionários
          <article className={classes.subtitle}>
              {' '}
            Resultados: &nbsp; <strong>{countEmployees}</strong> &nbsp;
            funcionários
          </article>
          </article>
          <AutocompleteObject setOptions={handleResults} setText={setText} autocompleteClass={classes.autocomplete} textFieldClass={classes.autocompleteTextField} label='Funcionários' />
        </section>
      }
    >
      <Styled.Main>
        {employees.map(({ branchCode, branchName, code, cpf, dateOfBirth, email, name, phoneNumber }) => (
          <section className={classes.card} key={code}>
            <article className={classes.infos}>
              <span>Nome Completo</span>
              <p className={classes.infoValue}>{name}</p>
            </article>
            <article className={classes.infos}>
              <span>E-mail</span>
              <p className={classes.infoValue}>{email}</p>
            </article>
            <article className={classes.infos}>
              <span>Data de Nascimento</span>
              <p className={classes.infoValue}>{new Date(dateOfBirth).toLocaleDateString('pt-Br')}</p>
            </article>
            <article className={classes.infos}>
              <span>Filial</span>
              <p className={classes.infoValue}>{branchName}</p>
            </article>
            <Tooltip title='Editar Funcionário' placement='top'>
              <Button
                onClick={() => {
                  setEditOpened(true)
                  setSelectedEmployee({ branchCode, branchName, code, cpf, dateOfBirth, email, name, phoneNumber })
                }}
              >
                {' '}
                <EditIcon />{' '}
              </Button>
            </Tooltip>
          </section>
        ))}
        <Pagination count={countPages} color="secondary" onChange={(evt, selectedPage) => setSelectedPage(selectedPage)}  />
      </Styled.Main>

      <SidePage
        title='Dados do Funcionário'
        opened={editOpened}
        setOpened={setEditOpened}
        mainStyle={{ height: 'calc(100% + 50px)' }}
      >
        <EmployeeEdit branchesOffice={branchesOffice} updateEmployees={updateEmployees} selectedEmployee={selectedEmployee} />
      </SidePage>

      <SidePage
        title='Cadastrar Funcionário'
        opened={createOpened}
        setOpened={setCreateOpened}
        mainStyle={{ height: 'calc(100% + 50px)' }}
      >
        <EmployeeCreateForm onSubmit={createEmployees} branchesOffice={branchesOffice} />
      </SidePage>

      <Button
        variant='contained'
        color='primary'
        className={classes.floatingButton}
        onClick={() => { setCreateOpened(true) }}
      >
        + Adicionar Funcionário
      </Button>

      <Alert openState={alert.opened} severity={alert.severity}>
        {alert.msg}
      </Alert>
    </Layout>
  )
}
