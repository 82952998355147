import { getLink } from '../../shared/services/linkSelfService'

const useCopyLinkButton = ({ code, showFeedBack, showFeedBackError }) => {
  const copyLink = async () => {
    try {
      const { link } = await getLink(code)
      const textArea = document.createElement('textarea')
      textArea.value = link
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy')
      textArea.remove()
      showFeedBack(true)
    } catch(e) {
      return showFeedBackError ? showFeedBackError(true) : e
    }
  }
  return { copyLink };
}

export default useCopyLinkButton;