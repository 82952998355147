import { getItem, setItem } from '../../utils/storage';

const DEFAULT_USER = {
  identification: "",
  name: "",
  fullName: "",
  email: "",
  accessToken: null,
  refreshToken: null,
  expiresIn: null,
}

const INITIAL_STATE = {
  user: (getItem('user') || DEFAULT_USER)
};

export function userReducer(state = INITIAL_STATE, action) {

  if (action.type === 'USER_DATA') {
    const updatedData = { user: action.data };
    const user = { ...state.user, ...updatedData.user } 
    const result = { user }
    setItem('user', result.user)
    return result
  }

  if (action.type === 'USER_LOGOUT') {
    return DEFAULT_USER
  };

  return state;
}