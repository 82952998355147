import { object, string } from 'yup'

const numberMessageMin = 'A deve ter no mínimo 8 caracteres'
const numberMessageMax = 'A deve ter no máximo 15 caracteres'

export default object().shape({
  password: string()
    .min(8, numberMessageMin)
    .max(15, numberMessageMax),
  passwordConfirmation: string()
    .min(8, numberMessageMin)
    .max(15, numberMessageMax)
})
