import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const Title = styled(Typography)`
  &.MuiTypography-h6 {
    font-size: 16px;
    font-weight: normal;
    color: #707070;
  }
`;
