import React, { memo } from 'react';
import NumberFormat from 'react-number-format';

const MaskedTextField = ({ children, inputRef, name, onChange, ...props }) => {
  return (
    <NumberFormat
      {...props}
      name={name}
      type={props.type || 'number'}
      getInputRef={inputRef}
      onValueChange={values => { onChange({ target: { value: values.value, name } }) }}
    />
  )
}


export default memo(MaskedTextField);