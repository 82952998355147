import React, { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button, Container, Grid, Hidden, TextField } from '@material-ui/core'
import { Formik, Form } from 'formik'
import FormField from '../../FormField'
import DocumentTextField from '../../DocumentTextField'
import PhoneTextField from '../../PhoneTextField'
import validationSchema from './validationSchema'
import CpfInfos from '../../CpfInfos'
import useCpf from '../../../hooks/useCpf'
import * as Styled from './styled'
import { handleBlurValidation } from '../../../shared/services/gtmEventDispatcher'
import { useStyles } from '../muiStyle'

const UserForm = ({ onSubmit }) => {
  const { consultCpf } = useCpf()
  const classes = useStyles();

  const [title, label] = useMemo(
    () =>
      [
        'Me fale um pouco sobre você.',
        {
          cpf: 'Seu CPF',
          email: 'Seu e-mail',
          phoneNumber: 'Seu número de celular'
        }
      ], []
  )

  return (
    <Formik
      initialValues={{
        phoneNumber: '',
        cpf: '',
        email: '',
        dateOfBirth: '',
        name: '',
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({
        values,
        handleChange,
        setFieldTouched,
        handleBlur,
        isSubmitting,
        dirty,
        isValid,
        setFieldValue,
        errors
      }) => (
        <Form>
          <Container maxWidth='sm'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Styled.Title variant='h6'>{title}</Styled.Title>
              </Grid>

              <Grid item xs={12}>
                <FormField
                  fullWidth
                  name='cpf'
                  label={label.cpf}
                  variant='outlined'
                  onBlur={event => {
                    handleBlurValidation({
                      errors,
                      fieldName: 'cpf',
                      extraValidationKeys: ['dateOfBirth', 'name']
                    })
                    handleBlur(event)
                    setFieldTouched('cpf', true)
                  }}
                  onChange={consultCpf({ handleChange, setFieldValue })}
                  documentType='cpf'
                  component={DocumentTextField}
                />
              </Grid>
            </Grid>
          </Container>

          <CpfInfos {...values} />

          <Container
            maxWidth='sm'
            style={{ marginTop: !Boolean(values.name) && '12px' }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField
                  fullWidth
                  name='phoneNumber'
                  label={label.phoneNumber}
                  variant='outlined'
                  onBlur={ev => {
                    handleBlurValidation({
                      errors,
                      fieldName: 'phoneNumber'
                    })
                    handleBlur(ev)
                    setFieldTouched('phoneNumber', true)
                  }}
                  component={PhoneTextField}
                />
              </Grid>

              <Grid item xs={12}>
                <FormField
                  type='email'
                  fullWidth
                  name='email'
                  label={label.email}
                  variant='outlined'
                  component={TextField}
                />
              </Grid>

              <Hidden xsDown>
                <Grid item sm={3} />
              </Hidden>

              <Grid item xs={12} sm={12} align='center'>
                <Button
                  className={classes.root}
                  fullWidth
                  type='submit'
                  color='primary'
                  variant='contained'
                  disabled={
                    isSubmitting || !dirty || !isValid
                  }
                >
                  Próximo
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Form>
      )}
    </Formik>
  )
}

UserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
}

export default memo(UserForm)
