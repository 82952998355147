import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import * as Styled from './style'
import Layout from '../../components/Layout'
import { clear } from '../../utils/storage';
import { useHistory } from 'react-router-dom'
import ProfileForm from '../../components/Forms/Profile'
import UpdatePasswordForm from '../../components/Forms/UpdatePasswordForm'
import { put as putMe } from '../../shared/services/me';
import { put as putUser } from '../../shared/services/user';
import Alert from '../../components/Alert'
import { get } from 'lodash'
import SidePage from '../../components/SidePage'

const useStyle = makeStyles(theme => ({
  title: {
    fontSize: '2rem',
    color: '#7d8591',
    '@media(max-width: 767px)': {
      marginLeft: 20,
    }
  },
  personalDataInfos: {
    width: '50%',
    '@media(max-width: 767px)': {
      width: '100%',
    }
  },
  personalDataIds: {
    display: 'flex',
    marginTop: 45,
    fontSize: '.8rem',
    padding: '11px 26px',
    '@media(max-width: 767px)': {
      padding: '11px 20px',
    }
  },
  personalDataArticle: {
    marginRight: 20
  },
  personalDataArticleValue: {
    fontSize: '1.2rem',
  },
  logout: {
    padding: '11px 26px',
    position: 'absolute',
    right: 20,
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#0091FF',
    '@media(max-width: 767px)': {
      top: 50,
    }
  },
  updatePass: {
    margin: '30px 0',
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#0091FF',
    fontWeight: 600,
    display: 'block',
    '@media(max-width: 767px)': {
      margin: '30px 0 30px 25px',
    }
  },
  myPasswordSection: {
    marginTop: 80,
  }
}))

export default function ProfilePage() {

  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector(state => state.userReducer.user)
  const [alert, setAlert] = useState({ severity: 'error', opened: false, msg: '' })
  const [updatePasswordOpened, setUpdatePasswordOpened] = useState(false)
  const classes = useStyle()

  const logout = () => {
    clear()
    history.go('/login')
    dispatch({ type: 'USER_LOGOUT' })
  }

  const submitProfileUpdate = async (values) => {
    try {
      const me = await putMe(values)
      dispatch({ type: 'USER_DATA', data: { ...user, ...me } })
      setAlert({ severity: 'success', opened: true, msg: 'Dados atualizados com sucesso!' })
    } catch (e) {
      setAlert({ severity: 'error', opened: true, msg: get(e, 'messages[0].text', 'Ocorreu um erro inesperado favor tente mais tarde!') })
    }
  }

  const submitUpdatePassword = async (values) => {
    try {
      await putUser(values)
      setUpdatePasswordOpened(false)
      setAlert({ severity: 'success', opened: true, msg: 'Senha atualizada com sucesso!' })
    } catch (e) {
      setAlert({ severity: 'error', opened: true, msg: get(e, 'messages[0].text', 'Ocorreu um erro inesperado favor tente mais tarde!') })
    }
  }

  return (
    <Layout
      hideFooter={true}
      Title='Meus Dados'
    >
      <Styled.Main>
        <span className={classes.title}>Dados Pessoais</span>
        <section className={classes.personalDataInfos}>
          <section className={classes.personalDataIds}>
            <article className={classes.personalDataArticle}>
              <span>CPF</span>
              <p className={classes.personalDataArticleValue}><strong> {user.identification} </strong></p>
            </article>
            <article className={classes.personalDataArticle}>
              <span>Nome</span>
              <p className={classes.personalDataArticleValue}><strong> {user.fullName} </strong></p>
            </article>
          </section>
          <ProfileForm {...user} onSubmit={submitProfileUpdate} />
        </section>

        <button className={classes.logout} onClick={logout}>
          Logout
        </button>

        <section className={classes.myPasswordSection}>
          <span className={classes.title}>Minha Senha</span>
          <button className={classes.updatePass} onClick={() => setUpdatePasswordOpened(true)}>
            Alterar Senha
        </button>
        </section>
      </Styled.Main>

      <Alert openState={alert.opened} severity={alert.severity}>
        {alert.msg}
      </Alert>

      <SidePage
        title='Alterar Senha'
        opened={updatePasswordOpened}
        setOpened={setUpdatePasswordOpened}
        mainStyle={{ height: 'calc(100% + 50px)' }}
      >
        <UpdatePasswordForm onSubmit={submitUpdatePassword} buttonTitle='Alterar Senha' />
      </SidePage>
    </Layout>
  )
}
