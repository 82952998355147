import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import ContrastedContainer from '../ContrastedContainer';
import DataField from '../DataField';
import * as Styled from './styled';

const CpfInfos = ({ name }) => (
  name && (
    <ContrastedContainer>
      <Styled.Content container spacing={1}>
        <Grid item xs={12}>
          <DataField label="Nome" text={name} />
        </Grid>
      </Styled.Content>
    </ContrastedContainer>
  )
);

CpfInfos.propTypes = {
  name: PropTypes.string,
};

CpfInfos.defaultProps = {
  name: ''
}

export default memo(CpfInfos);