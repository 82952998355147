import React, { memo } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'

const DialogConfirm = ({ title, description, handleAccept, handleRefuse, open }) => {

  return (
    <Dialog
      open={open}
    >
      <DialogTitle id='alert-dialog-title'>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          { description }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRefuse} color='primary'>
          Recusar
        </Button>
        <Button onClick={handleAccept} color='secondary' autoFocus>
          Aceitar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default memo(DialogConfirm)
