import { get } from 'lodash'
import { PRIVATE_ROUTES, SIGNUP_ROUTES } from '../../../routes/constants'

const isDevelopment = process.env.NODE_ENV === 'development'

const getFormLocation = () =>
  [...PRIVATE_ROUTES, ...SIGNUP_ROUTES].find(({ path }) => path === window.location.pathname)

const dataLayerFunction = () =>
  isDevelopment ? console.info : (window.dataLayer || []).push

const dispatchGtmEvent = event => payload => {
  const formLocation = getFormLocation()

  dataLayerFunction()({
    event,
    formLocation: get(formLocation, 'step'),
    ...payload
  })
}

const dispatchGTMStep = () => {
  const formLocation = getFormLocation()
  const path = get(formLocation, 'path')
  const virtualPageTitle = get(formLocation, 'title')

  dataLayerFunction()({
    event: 'VirtualPageView',
    virtualPageURL: `/vendedor/${path}`,
    virtualPageTitle
  })
}

export const dispatchFormFieldFillEvent = dispatchGtmEvent('formfieldfill')
export const dispatchFormFieldError = dispatchGtmEvent('formfielderror')
export const dispatchClickEvent = dispatchGtmEvent('clickbutton')
export const dispatchOpenDialog = dispatchGtmEvent('opendialog')
export const dispatchRouteChanged = (payload) => {
  dispatchGTMStep()
  dispatchGtmEvent('vendedor')(payload)
}

export const handleBlurValidation = ({
  errors,
  fieldName,
  extraValidationKeys = []
}) => {
  const hasError =
    get(errors, fieldName) ||
    extraValidationKeys.some(key => Boolean(get(errors, key)))
  hasError
    ? dispatchFormFieldError({ label: fieldName })
    : dispatchFormFieldFillEvent({ label: fieldName })
}
