import { getItem, setItem } from '../../utils/storage';

const DEFAULT_DATA = {
  code: '',
  files: {},
  address: {},
}

const INITIAL_STATE = {
  signup: (getItem('signup') || DEFAULT_DATA)
};

export function signupReducer(state = INITIAL_STATE, action) {

  if (action.type === 'SIGNUP_DATA') {
    const updatedData = { signup: action.payload };
    const savedData = getItem('signup') 
    setItem('signup', savedData ? {...savedData, ...updatedData.signup} : updatedData.signup)
    return { signup: { ...state.signup, ...updatedData.signup } }
  }

  if (action.type === 'CLEAN_SIGNUP_DATA') {
    setItem('signup', null)
    return DEFAULT_DATA
  }

  return state;
}