import React, { memo, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import useDebounce from '../../hooks/useDebounce';
import SearchIcon from '@material-ui/icons/Search';

const Autocomplete = ({ getOptionLabel, service, label, onChange, renderOption, autocompleteClass, textFieldClass }) => {
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const debouncedSearchTerm = useDebounce(inputValue, 500);

  const handleChange = event => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    let active = true;
    if (active && debouncedSearchTerm.length >= 3) {
      service(debouncedSearchTerm).then(setOptions)
    }

    return () => {
      active = false
    }
  }, [debouncedSearchTerm])

  return (
    <MuiAutocomplete
      getOptionLabel={getOptionLabel}
      className={autocompleteClass}
      filterOptions={x => x}
      options={options}
      includeInputInList
      freeSolo
      onChange={onChange}
      renderInput={params => (
        <TextField
          {...params}
          className={textFieldClass}
          label={label}
          variant="outlined"
          fullWidth
          onChange={handleChange}
          autoComplete="off"
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
        />
      )}
      renderOption={renderOption}
    />
  );
}

Autocomplete.defaultProps = {
  getOptionLabel: option => (typeof option === 'string' ? option : option.text),
  renderOption: option => <option value={typeof option.value === 'string' ? option.value : JSON.stringify(option.value)}>{option.text}</option>
};

export default memo(Autocomplete);