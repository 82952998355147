import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import Alert from '../../../components/Alert'
import { update } from '../../../shared/services/password'
import {
  dispatchRouteChanged,
  dispatchClickEvent
} from '../../../shared/services/gtmEventDispatcher'
import PasswordForm from '../../../components/Forms/PasswordForm'

const Password = ({ location }) => {
  const history = useHistory()
  const { code } = useSelector(state => state.signupReducer.signup)

  const [snackbarErrorOpened, setSnackbarErrorOpened] = useState(false)
  const [snackbarErrorMessage, setSnackbarErrorMessage] = useState('')

  useEffect(() => {
    dispatchRouteChanged({
      pageCategory: 'vendedor',
      urlpasso: window.location.pathname
    })
  }, [])

  const handleSubmit = (values, formikProps) => {
    dispatchClickEvent({ label: 'Próximo', type: 'submit' })
    if (values) {
      update({ code, body: values })
        .then(_ => {
          history.push('/cadastrar/sucesso')
        })
        .catch(e => {
          setSnackbarErrorMessage(
            get(
              e,
              'messages[0].text',
              'Ocorreu um erro! Favor tente mais tarde!'
            )
          )
          setSnackbarErrorOpened(true)
        })
    }
  }

  return (
    <>
      <PasswordForm onSubmit={handleSubmit} />{' '}
      <Alert openState={snackbarErrorOpened} severity='error'>
        {snackbarErrorMessage}
      </Alert>
    </>
  )
}

export default Password
