import React, { memo, useEffect } from 'react'
import useStyles from './theme'
import * as Styled from './styles'
import { ReactSVG } from 'react-svg'
import Logo from '../../assets/svgs/logo-sidemenu.svg'

function MenuMobile({
  opened,
  setOpened,
  links,
  children
}) {
  const classes = useStyles()

  useEffect(() => {
  }, [opened])

  const toggleDrawer = value => {
    setOpened(prevState => value || !prevState)
  }

  return (
    <Styled.Detail onClick={() => toggleDrawer()}>
      <Styled.Drawer
        className={classes.drawer}
        paper={{ flexFlow: 'row' }}
        anchor={'left'}
        open={opened}
        onClose={() => {
          toggleDrawer(false)
        }}
      >
        <Styled.LogoWrapper>
          <ReactSVG src={Logo} />
        </Styled.LogoWrapper>
        <Styled.MenuBarGroup>
          {links.map(({ to, isActive, title, icon: Icon }, i) => (
            <Styled.MenuBarLink
              key={i}
              to={to}
              activeClassName='current'
              active={isActive}
            >
              <Styled.MenuBarItem>
                <Icon />
                {title}
              </Styled.MenuBarItem>
            </Styled.MenuBarLink>
          ))}
          {children}
        </Styled.MenuBarGroup>
        <Styled.Close />
      </Styled.Drawer>
    </Styled.Detail>
  )
}

export default memo(MenuMobile)