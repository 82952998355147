import React, { memo, useState } from 'react'
import Section from '../Section'
import { Button, makeStyles, TextField } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    fontSize: 18,
    gridColumnGap: '2%',
    gridTemplateColumns: '60% 15% 23%',
    gridTemplateRows: '30px 20px',
    alignItems: 'center',
    gridTemplateAreas: `
    "description protocol button"
    "link protocol button"
    `,
    margin: 'auto',
    width: '80%',
    '@media(max-width: 767px)': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: 14,
      justifyContent: 'space-between',
      margin: '19px 40px 32px'
    }
  },
  link: {
    color: '#008EFF',
    fontSize: 12,
    gridArea: 'link',
    textDecoration: 'underline',
    marginTop: 10
  },
  button: {
    gridArea: 'button',
    height: '100%',
    borderRadius: 50,
    marginTop: '0 !important',
    '@media(max-width: 767px)': {
      height: 40,
      width: '100%',
      marginTop: '0 !important'
    }
  },
  protocolTextField: {
    '@media(max-width: 767px)': {
      margin: `20px 0;`
    }
  },
}))

const ImportExcelSection = () => {
  const classes = useStyles()
  const [protocol, setProtocol] = useState('')

  return (
    <Section title='Importar Excel'>
      <section className={classes.root}>
        <span style={{ gridArea: 'description' }}>
          Para planos com mais de 30 vidas você pode importar um arquivo Excel
          para efetuar o cadastro.
        </span>
        <TextField
          style={{ gridArea: 'protocol' }}
          id='protocol'
          label='Protocolo'
          fullWidth
          color={'primary'}
          type='text'
          variant='outlined'
          className={classes.protocolTextField}
          onChange={({ currentTarget }) => {
            setProtocol(currentTarget.value)
          }}
        />
        <Button
          variant='outlined'
          value={protocol}
          component={'a'}
          rel='noopener noreferrer'
          target='_blank'
          disabled={!protocol}
          href={`${process.env.REACT_APP_UPLOAD}${protocol}`}
          className={classes.button}
        >
          Importar
        </Button>
        <a
          href={
            'https://files.w.dental/?bucketName=wdental-app-documentos&folderName=import-dependent-broker&fileName=import_w.dental.xlsx&fileExtension=.xlsx'
          }
          rel='noopener noreferrer'
          target='_blank'
          className={classes.link}
        >
          {' '}
          Baixar modelo de arquivo Excel{' '}
        </a>
      </section>
    </Section>
  )
}

export default memo(ImportExcelSection)
