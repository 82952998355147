import React, { memo } from 'react';
import { TextField } from '@material-ui/core';
import MaskedTextField from '../MaskedTextField';

const ZipCodeTextField = props => (
  <MaskedTextField
    {...props}
    format="##.###-###"
    type="tel"
    customInput={TextField}
  />
);

export default memo(ZipCodeTextField);