import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Snackbar, Fade } from '@material-ui/core'
import CopyIcon from '@material-ui/icons/FileCopyOutlined'
import CheckIcon from '@material-ui/icons/Check'
import SidePage from '../SidePage'
import PlanDetail from '../PlanDetail'
import { geDetail } from '../../shared/services/plans'
import useCopyLinkButton from '../../hooks/useCopyPlanLink'
import useSelfServiceRedirect from '../../hooks/useSelfServiceRedirect'
import PhoneIcon from '@material-ui/icons/Phone'

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginRight: 20,
    minWidth: 203,
    height: 278,
    background: 'white',
    borderRadius: 4,
    boxShadow: '0px 3px 6px #0000000F',
    width: `calc(33% - 20px)`
  },
  header: {
    height: 72,
    display: 'flex',
    alignItems: 'center',
    padding: '0 16px',
    fontSize: 20,
    fontWeight: 500,
    color: '#161A47',
    position: 'relative'
  },
  headerCompany: {
    fontSize: 17
  },
  shareLink: {
    position: 'absolute',
    right: 16,
    top: 16
  },
  gracePeriodSection: {
    height: 24,
    backgroundColor: '#65D800',
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    justifyContent: 'center',
    fontSize: 9
  },
  priceSection: {
    display: 'flex',
    flexDirection: 'column',
    padding: '14px 16px 19px'
  },
  priceLabel: {
    fontSize: 12,
    fontWeight: 500
  },
  footer: {
    height: 32,
    background: '#E9F0F8',
    fontSize: 12,
    color: '#505050',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    fontWeight: 500,
    cursor: 'pointer'
  }
}))

const Plan = ({
  value,
  period,
  code,
  title,
  isCompany,
  hasOtrhodontics,
  needForm
}) => {
  const classes = useStyles()
  const [snackbarOpened, setSnackbarOpened] = useState(false)
  const [detailsOpened, setDetailsOpened] = useState(false)

  const { copyLink } = useCopyLinkButton({
    code,
    showFeedBack: setSnackbarOpened
  })
  const { redirectToSelfService } = useSelfServiceRedirect({ code })

  const openDetail = () => {
    geDetail(code).then(data => {
      setDetailsOpened(true)
    })
  }

  return (
    <section className={classes.wrapper}>
      <header
        className={`${classes.header} ${
          isCompany ? classes.headerCompany : null
        }`}
      >
        {title}
        {!needForm && (
          <button className={classes.shareLink} onClick={copyLink}>
            <CopyIcon />
          </button>
        )}
      </header>

      <main>
        <section className={classes.gracePeriodSection}>
          <strong style={{ fontSize: 10, marginRight: 3 }}>
            Carência Zero{' '}
          </strong>{' '}
          {!isCompany && 'no plano anual'}
        </section>
        <section className={classes.priceSection}>
          <span className={classes.priceLabel}>Apenas</span>
          <span>
            <strong style={{ fontSize: 16 }}>R$</strong>
            <strong style={{ fontSize: 26 }}>{value.substring(3)}</strong>
          </span>
          <span>por {period}</span>
          {needForm ? (
            <Button
              style={{
                height: 40,
                borderRadius: 50,
                padding: 0
              }}
              fullWidth
              type='submit'
              color='primary'
              variant='outlined'
              aria-label='enviar'
              className='padding'
              component='a'
              href='tel:+552130306853'
            >
              <PhoneIcon /> (21) 3030-6853
            </Button>
          ) : (
            <Button
              style={{
                height: 40,
                borderRadius: 50,
                backgroundColor: '#121a3d',
                padding: 0
              }}
              fullWidth
              type='submit'
              color='primary'
              variant='contained'
              aria-label='enviar'
              className='padding'
              onClick={redirectToSelfService}
            >
              Contratar
            </Button>
          )}
        </section>
      </main>

      <footer className={classes.footer} onClick={openDetail}>
        <span>Detalhes do Plano</span>
      </footer>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpened}
        onClose={() => {
          setSnackbarOpened(false)
        }}
        TransitionComponent={Fade}
        message='Url copiada para enviar ao seu cliente'
        autoHideDuration={5000}
        action={<CheckIcon fontSize='default' />}
      />

      <SidePage
        title='Detalhes do Plano'
        opened={detailsOpened}
        setOpened={setDetailsOpened}
        mainStyle={{ height: 'calc(100% + 50px)' }}
      >
        <PlanDetail
          code={code}
          isCompany={isCompany}
          hasOtrhodontics={hasOtrhodontics}
          planTitle={title}
          needForm={needForm}
        />
      </SidePage>
    </section>
  )
}

Plan.defaultProps = {
  lifes: 0,
  value: '',
  period: '',
  code: '',
  title: '',
  isCompany: false
}

Plan.propTypes = {
  lifes: PropTypes.number,
  value: PropTypes.string,
  period: PropTypes.string,
  code: PropTypes.string,
  title: PropTypes.string,
  isCompany: PropTypes.bool
}

export default memo(Plan)
