import { get } from 'lodash';
import React, { memo } from 'react';
import { Field } from 'formik';
import Check from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CORRECT } from '../../shared/styles/colors';
import { handleBlurValidation } from '../../shared/services/gtmEventDispatcher';

const FormField = ({ children, component: Component, name, hideCheckIcon, ...props }) => (
  <Field name={name}>
    {({ field, form }) => {
      const { submitCount, touched, errors } = form;
      const { onChange, onBlur, value, ...fieldProps } = field;
      const error = get(errors, name, '') || props.specificerror;
      const canValidate = Boolean(props.specificerror || submitCount > 0 || get(touched, name))
      const isValid = Boolean(canValidate && !error);
      const hasError = Boolean(canValidate && error);
      const handleChange = props.onChange || onChange;
      const handleBlur = (event) => {
        if (!props.onBlur) {
          handleBlurValidation({ errors, fieldName: name });
        }
        const fn = props.onBlur || onBlur;
        fn(event);
      }

      return (
        <Component
          className="ooop"
          {...fieldProps}
          onChange={handleChange}
          onBlur={handleBlur}
          error={hasError}
          helperText={hasError && error}
          defaultValue={value}
          {...props}
          InputProps={{
            ...props.InputProps,
            ...(!!props.loading
              ? { endAdornment: <CircularProgress size={30}/> }
              : {}
            ),
            ...(isValid && !hideCheckIcon
              ? { endAdornment: <Check position="end" style={{ color: CORRECT }} /> }
              : {}
            )
          }}
        >
          {children}
        </Component>
      );
    }}
  </Field >
);

export default memo(FormField);