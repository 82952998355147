import { isCNPJ } from "brazilian-values";
import { verifyCnpj } from "../../shared/services/cnpj";
import { get } from 'lodash'

const useCnpj = (keys = [
  'openingDate',
  'businessName',
  'phoneNumber',
  'fantasyName',
  'address',
]) => {

  const setInfo = (setFieldValue, setShowPhoneField) => (payload = {}) => {
    for (let key of keys) {
      const value = payload[key];
      setFieldValue(key, value);
    }
    const phone = get(payload, 'phoneNumbers[0]', '')
    setFieldValue('phoneNumber', phone);
    setShowPhoneField(payload.businessName && !phone)
  }

  const consultCnpj = ({ handleChange, setFieldValue, setFieldError, setShowPhoneField, setLoadingCnpj }) => async (event) => {
    const handleChangeInfo = setInfo(setFieldValue, setShowPhoneField);
    handleChangeInfo();
    handleChange(event);
    const { value } = event.target;

    try {
      if (isCNPJ(value)) {
        setLoadingCnpj(Number(true))
        const response = await verifyCnpj(value);
        handleChangeInfo(response);
        setLoadingCnpj(Number(false))
      }
    } catch (error) {
      setFieldError('cnpj', 'Falha ao consultar seu CNPJ. Caso a sua empresa tenha menos de 6 meses, entre em https://w.dental/contato')
      setLoadingCnpj(Number(false))
    }
  }

  return { consultCnpj };
}

export default useCnpj;