import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    margin: '80px 0 16px',
    '@media(max-width: 767px)': {
      margin: '16px 30px',
      width: 'calc(100% - 60px)'
    }
  },
  link: {
    color: '#008EFF',
    textDecoration: 'underline'
  },
  title: {
    fontSize: 14,
    color: '#505050',
    '@media(min-width: 767px)': {
      fontSize: 18
    }
  },
  infos: {
    backgroundColor: 'white',
    width: '100%',
    minHeight: 120,
    display: 'flex',
    justifyContent: 'space-evenly',
    '@media(max-width: 1080px)': {
      flexWrap: 'wrap',
      minHeight: 290,
    }
  }
}))

const Section = ({ children, title, link, infosStyle, isFirst }) => {
  const classes = useStyles()
  return (
    <>
      <section className={classes.header} style={isFirst ? { marginTop: 40 } : null}>
        <article className={classes.title}>{title}</article>
        {link && (
          <a className={classes.link} href={link.href}>
            { link.label }
          </a>
        )}
      </section>
      <section style={infosStyle} className={classes.infos}>{children}</section>
    </>
  )
}

Section.defaultProps = {
  isFirst: false,
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  infosStyle: PropTypes.object,
  isFirst: PropTypes.bool,
  link: PropTypes.shape({
    label: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired
  })
}

export default memo(Section)
