import request from  '@w.dental/request';
import { getItem, clear } from '../../utils/storage';
import getCryptedKey from '../../helpers/getCryptedKey'

const API = process.env.REACT_APP_ESB;

async function transformResponse(result = {}) {
  return result && result.data && result.data.value;
}

async function onError(response) {
  if (response.status === 401 && (getItem('user') || {}).accessToken) {
    clear();
    window.location.pathname = '/';
  }
  throw response;
}

function _getAuthorizationHeader() {
  let token = (getItem('user') || {}).accessToken;
  return token ? { Authorization: `bearer ${token}` } : {};
}

export const httpHandler = request(API, {
  interceptors: {
    transformResponse,
    onError
  },
  headers: new Headers({ 'Content-Type': 'application/json; charset=utf-8', 'wd-key': getCryptedKey(),  ..._getAuthorizationHeader() })
}) 

export default httpHandler;
