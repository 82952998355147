import React, { memo } from 'react'
import * as Styled from './styled'
import { ReactSVG } from 'react-svg'
import Logo from '../../assets/svgs/logo-footer.svg'
import Ans from '../../assets/svgs/ans.svg'

const Footer = ({ footerWrapperStyle }) => {
  return (
    <Styled.Wrapper style={footerWrapperStyle}>
      <Styled.TopWrapper>
        <Styled.Logo>
        <ReactSVG src={Logo} alt='W.Dental' />
        </Styled.Logo>
      </Styled.TopWrapper>

      <Styled.ANS alt='ANS - No. 421049' >
        <ReactSVG src={Ans} />
      </Styled.ANS>

      <Styled.FooterInfos>
        <Styled.AddressPart>W.Dental | CRO/RJ | EPAO: 4781</Styled.AddressPart>
        <Styled.AddressPart hasSpacing>
          Responsável Técnico Dr. Michell Giordano de Oliveira
        </Styled.AddressPart>
        <Styled.AddressPart hasSpacing>
          CRO/RJ: 40049 | CNPJ: 27.119.150/0001-75
        </Styled.AddressPart>
        <Styled.AddressPart
          as='a'
          href='http://maps.google.com/?q=Av%20das%20Am%C3%A9ricas%20500,%20bloco%2015,%20sala%20301'
          target='_blank'
          rel='noopener noreferrer'
        >
          Av das Américas 500 bloco 15, sala 301
          <p>Downtown - Barra da Tijuca</p>
        </Styled.AddressPart>
      </Styled.FooterInfos>
    </Styled.Wrapper>
  )
}

export default memo(Footer)
