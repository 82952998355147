import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core'
import Section from '../Section'
import { ReactSVG } from 'react-svg'
import Pdf from '../../assets/svgs/pdf.svg'
import Manual from '../../assets/pdfs/manual_de_vendas_wdental.pdf'
import ManualPlans from '../../assets/pdfs/manual-planos-e-coberturas.pdf'

const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto',
    width: '80%',
    height: '70%',
    display: 'flex',
    '@media(max-width: 767px)': {
      flexFlow: 'column',
    }
  },
  items: {
    width: '50%',
    height: '100%',
    display: 'grid',
    fontSize: 18,
    gridColumnGap: '4%',
    gridTemplateColumns: '40px calc(96% - 40px)',
    gridTemplateRows: 'repeat(2, 50%)',
    alignItems: 'center',
    gridTemplateAreas: `
    "icon title"
    "icon link"
    `,
    margin: 'auto',
    '&:first-child': {
      borderRight: '1px solid #DDE0E5',
    },
    '&:not(:first-child)': {
      marginLeft: '10%',
    },
    '@media(max-width: 767px)': {
       width: '100%',
       '&:first-child': {
        borderRight: 'none',
      },
      '&:not(:first-child)': {
        marginLeft: '0',
      },
    }
  },
  link: {
    color: '#008EFF',
    fontSize: 12,
    gridArea: 'link',
    textDecoration: 'underline',
    alignSelf: 'start',
  },
  title: {
    gridArea: 'title',
    alignSelf: 'end',
  },
}))

const ITEMS = [
  {title: 'Manual Fluxo de Vendas', link: Manual },
  {title: 'Manual Planos e Coberturas', link: ManualPlans},
]

const SupportMaterialSection = ({ children }) => {
  const classes = useStyles()

  return (
    <Section
      title='Material de Apoio'
    >
      <section className={classes.root}>
        {ITEMS.map(({title, link}) => (
          <section className={classes.items} key={link}>
            <ReactSVG src={Pdf} style={{ gridArea: 'icon' }}/>
            <span className={classes.title}>{ title }</span>
            <a href={ link } rel="noopener noreferrer" target="_blank" className={classes.link}> Baixar PDF </a>
          </section>
        ))}
      </section>
    </Section>
  )
}

export default memo(SupportMaterialSection)
