import styled from 'styled-components';
import { flexBox } from '../../styles/mixins';
import media from 'styled-media-query';

export const Header = styled.header`
  ${flexBox('row nowrap', 'space-between', 'flex-start')};
  width: 100%;
  padding: 52px 40px;
  box-shadow: 0px 2px 6px #0000000D;

	${media.lessThan('767px')`
    z-index: 2;
    min-height: 64px;
    font-size: 18px;
    padding: 13px 30px;
    justify-content: flex-start;
    background-color: white;
    position: absolute;
  `}
`