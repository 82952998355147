import React, { memo, useMemo } from 'react';
import { AppBar, DialogContent, Typography, Toolbar, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as Styled from './styled';

const Dialog = ({ children, open, onClose, subtitle, title, maxWidth, titleStyle, dialogContentStyle, hasntHeader, halfModal }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [styleDialog, styleCloseBtn, styleAppBar, styleToolBar] = useMemo(() => (
    hasntHeader ? [
      { height: 'calc(100% - 105px)', marginTop: 105 },
      { position: 'absolute', right: 0, top: 8, color: 'black' },
      { marginBottom: 48 },
      { minHeight: 8 }
    ] : Array(5)
  ), [hasntHeader]);
  return (
    <Styled.WDialog halfmodal={Number(halfModal)} fullWidth fullScreen={fullScreen} open={open} onClose={onClose} maxWidth={maxWidth} style={styleDialog}>
      {
        fullScreen
          ?
          <AppBar style={{ position: 'relative', ...styleAppBar }}>
            <Toolbar style={styleToolBar}>
              <IconButton style={styleCloseBtn} edge="start" color="inherit" onClick={onClose} aria-label="fechar">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" component="span">
                {title}
              </Typography>
            </Toolbar>
          </AppBar>
          :
          <Styled.DialogTitle>
            <IconButton
              aria-label="fechar"
              onClick={onClose}
              style={{
                position: 'absolute',
                top: theme.spacing(1),
                right: theme.spacing(1)
              }}
            >
              <CloseIcon />
            </IconButton>
          </Styled.DialogTitle>
      }

      <DialogContent style={{ padding: '24px 2px', ...dialogContentStyle}}>
        {!fullScreen &&
          <Styled.Title variant="h5" align="center" style={titleStyle}>{title}</Styled.Title>
        }
        {subtitle && <Typography variant="body2" component="h3" align="center">{subtitle}</Typography>}
        {children}
      </DialogContent>
    </Styled.WDialog>
  )
};

Dialog.defaultProps = {
  subtitle: '',
  maxWidth: 'xs'
};

export default memo(Dialog);
