import React, { memo, useEffect } from 'react';
import Header from '../Header';
import { dispatchRouteChanged } from '../../../shared/services/gtmEventDispatcher';
import * as Styled from './styled';

const Layout = ({ children, step, title, hideHeader, noMargin, maxSteps }) => {
  useEffect(() => {
    if (window.location.pathname !== '/') {
      dispatchRouteChanged({ pageCategory: 'vendedor', urlpasso: window.location.pathname })
    }
  }, [])

  return (
    <>
      {!hideHeader && <Header noMargin={noMargin} step={step} title={title} maxSteps={maxSteps}/>}
      <Styled.Main>
        {children}
      </Styled.Main>
    </>
  );
};

Layout.defaultProps = {
  step: 1,
  maxSteps: 5,
  title: '',
  noMargin: false,
};

export default memo(Layout);