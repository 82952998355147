import styled from 'styled-components'

export const Main = styled.main`
  overflow-y: auto;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 20px;
  height: calc(100% - 330px);
  @media(max-width: 767px) {
    height: calc(100% - 128px);
    margin-top: 128px;
  }
`
