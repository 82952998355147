import styled from 'styled-components';
import { Container, Typography } from '@material-ui/core';
import { LIGHT_GREY, TEXT } from '../../../shared/styles/colors';

export const Wrapper = styled.section`
  position: relative;
`;

export const ItemsContainer = styled(Container)`
  &.MuiContainer-root {
    border-top: 4px solid ${LIGHT_GREY};
    border-bottom: 4px solid ${LIGHT_GREY};
    margin-top: 24px;
    max-height: 210px;
    overflow-y: auto;
    padding: 0;
  }
`;

export const Text = styled(Typography)`
  &.MuiTypography-body1 {
    font-size: 12px;
    color: ${TEXT};
  }
`;