import styled from 'styled-components';
import {fontSize} from '../../styles/mixins'
import media from 'styled-media-query';

export const Paragraph = styled.article`
	${fontSize(52)};
	line-height: 1.4;
    align-self: flex-start;
	width: 100%;

	${media.lessThan('767px')`
		${fontSize(18)}
	`}
`;

export default {
	Paragraph
}