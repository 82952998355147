import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { get } from 'lodash'
import Alert from '../../../components/Alert'
import { create } from '../../../shared/services/company'
import { update as updateAddress } from '../../../shared/services/address'
import {
  dispatchRouteChanged,
  dispatchClickEvent
} from '../../../shared/services/gtmEventDispatcher'
import CompanyForm from '../../../components/Forms/CompanyForm'
import Company from '../../../models/Company'
import Address from '../../../models/Address'

const CompanyData = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [snackbarErrorOpened, setSnackbarErrorOpened] = useState(false)
  const [snackbarErrorMessage, setSnackbarErrorMessage] = useState('')

  useEffect(() => {
    dispatchRouteChanged({
      pageCategory: 'vendedor',
      urlpasso: window.location.pathname
    })
    dispatch({ type: 'CLEAN_SIGNUP_DATA' })
  }, [dispatch])

  const handleSubmit = async (values, formikProps) => {
    dispatchClickEvent({ label: 'Próximo', type: 'submit' })
    if (values) {
      const user = new Company({ ...values })
      const address = new Address({ ...values.address })
      try {
        const { code } = await create(user)
        await updateAddress({ code, body: address })
        dispatch({ type: 'SIGNUP_DATA', payload: { code, address } })
        history.push('/cadastrar/senha-empresa')
      } catch (e) {
        setSnackbarErrorMessage(
          get(
            e,
            'messages[0].text',
            'Ocorreu um erro! Favor tente mais tarde!'
          )
        )
        setSnackbarErrorOpened(true)
      }
    }
  }

  return (
    <>
      <CompanyForm onSubmit={handleSubmit} />{' '}
      <Alert openState={snackbarErrorOpened} severity='error'>
        {snackbarErrorMessage}
      </Alert>
    </>
  )
}

export default CompanyData
