import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import Alert from '../../../components/Alert'
import { update } from '../../../shared/services/documents'
import {
  dispatchRouteChanged,
  dispatchClickEvent
} from '../../../shared/services/gtmEventDispatcher'
import FileForm from '../../../components/Forms/FileForm'

const Files = ({ location }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { code } = useSelector(state => state.signupReducer.signup)

  const [snackbarErrorOpened, setSnackbarErrorOpened] = useState(false)
  const [snackbarErrorMessage, setSnackbarErrorMessage] = useState('')

  useEffect(() => {
    dispatchRouteChanged({
      pageCategory: 'vendedor',
      urlpasso: window.location.pathname
    })
  }, [])

  const handleSubmit = files => {
    dispatchClickEvent({ label: 'Próximo', type: 'submit' })

    if (files) {
      update({ code, body: files })
        .then(_ => {
          dispatch({ type: 'SIGNUP_DATA', payload: { ...files } })
          history.push('/cadastrar/senha')
        })
        .catch(e => {
          setSnackbarErrorMessage(
            get(
              e,
              'messages[0].text',
              'Ocorreu um erro! Favor tente mais tarde!'
            )
          )
          setSnackbarErrorOpened(true)
        })
    }
  }

  return (
    <>
      <FileForm onSubmit={handleSubmit} />{' '}
      <Alert openState={snackbarErrorOpened} severity='error'>
        {snackbarErrorMessage}
      </Alert>
    </>
  )
}

export default Files
