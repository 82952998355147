import CryptoJS from 'crypto-js'
import moment from 'moment'

export default function () {

  const today = moment().utcOffset('-03:00')

  const pass = `0283${(today.date() + 10)}2537${today.month() + 11}${today.year() * 2}`
  const key = CryptoJS.enc.Utf8.parse(pass);
  const iv = CryptoJS.enc.Utf8.parse('8080808080808080');
  const keySize = 128 / 8

  const encrypted = CryptoJS.AES.encrypt(process.env.REACT_APP_WD_KEY, key,
    {
      keySize,
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

  return encrypted.toString()
}
