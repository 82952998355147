import styled, { css } from 'styled-components'
import { white, PRIMARY_LIGHT } from '../../shared/styles/colors'
import { fontSize } from '../../shared/styles/mixins'

export const Wrapper = styled.footer`
  display: flex;
  flex-direction: column;
  padding: 0;
  background: ${PRIMARY_LIGHT};
  color: ${white};
  border-top: 1px solid #c6d2ec;
  margin-bottom: 0;
  width: 100%;
  @media (min-width: 600px) {
    display: grid;
    grid-template-areas: ' . logo . ans infos .';
    grid-template-columns: auto 200px auto 200px 240px auto;
    margin: auto;
    padding-top: 48px;
    grid-gap: 0 20px;
  }
`

export const Logo = styled.div`
  width: 132px;
  height: 92px;
  margin: 0 auto;
  margin-bottom: 42px;
`

export const TopWrapper = styled.section`
  padding-top: 48px;
  grid-area: logo;
  @media (min-width: 600px) {
    padding-top: unset;
    align-self: center;
  }
`

export const ANS = styled.div`
  margin: 0 auto 16px;
  grid-area: ans;
  @media (min-width: 600px) {
    align-self: center;
  }
`

export const AddressPart = styled.p`
  ${fontSize(10)};
  display: block;
  font-weight: 300;

  ${({ hasSpacing }) =>
    hasSpacing &&
    css`
      margin-bottom: 8px;
    `}
  @media(min-width: 600px) {
    text-align: left;
  }
`

export const FooterInfos = styled.section`
  color: #707070;
  margin-bottom: 16px;
  grid-area: infos;
  @media (min-width: 600px) {
    align-self: center;
  }
  @media (max-width: 767px) {
    text-align: center;
    margin-bottom: 60px;
  }
`

export const SocialLinksContainer = styled.nav`
  display: flex;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 48px;
  color: #121a3d;
`

export const BottomWrapper = styled.section`
  width: 100%;
  background-color: white;
`

export const ContactsArea = styled.section`
  width: 488px;
  padding: 68px 0 76px;
  margin: auto;
  display: flex;

  @media (max-width: 600px) {
    width: 100%;
    justify-content: center;
    flex-direction: column;
    padding: 48px 20px 32px;
  }
`
