import React, { memo, useState } from 'react'
import DragNDrop from '../../DragNDrop'
import { Button, makeStyles } from '@material-ui/core'
import * as Styled from './styled'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    bottom: 32,
    width: 'calc(100% - 80px)',
    padding: '16px 56px',
    '@media(min-width: 600px)': {
      top: 540,
      bottom: 'unset'
    }
  }
}))

const FileForm = ({ onSubmit, onClose }) => {
  const classes = useStyles()
  const { files } = useSelector(state => state.signupReducer.signup)
  let defaultIdentification = { base64: '', extension: '' }
  let defaultResidence = { base64: '', extension: '' }
  if (files && files.length) {
    defaultIdentification = files[0]
    defaultResidence = files[1]
  }
  const [identificationFile, setIdentificationFile] = useState(
    defaultIdentification.base64
  )
  const [residenceFile, setResidenceFile] = useState(defaultResidence.base64)
  const [identificationExtension, setIdentificationExtension] = useState(
    defaultIdentification.extension
  )
  const [residenceExtension, setResidenceExtension] = useState(
    defaultResidence.extension
  )

  function continueHandler () {
    onSubmit({
      files: [
        {
          base64: identificationFile,
          extension: identificationExtension,
          type: 2
        },
        { base64: residenceFile, extension: residenceExtension, type: 5 }
      ]
    })
  }

  return (
    <Styled.Wrapper>
      <DragNDrop
        dataId='identificationDoc'
        hasMarginTop={Number(true)}
        getBase64={setIdentificationFile}
        setExtension={setIdentificationExtension}
        title='Documento de Identificação'
        base64Default={identificationFile}
        subtitle='Identidade, CPF ou Carteira de Habilitação (CNH)'
      />

      <DragNDrop
        dataId='residencenDoc'
        hasMarginTop={Number(true)}
        base64Default={residenceFile}
        getBase64={setResidenceFile}
        setExtension={setResidenceExtension}
        title='Documento de Residência'
        subtitle='Comprovante de Residência Conta de água, luz ou telefone em seu nome.'
      />

      <Button
        className={classes.root}
        variant='contained'
        color='primary'
        type='submit'
        disabled={!identificationFile || !residenceFile}
        onClick={continueHandler}
      >
        Continuar
      </Button>
    </Styled.Wrapper>
  )
}

export default memo(FileForm)
