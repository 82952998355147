import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Theme from './shared/theme';

import GlobalStyle from './shared/styles/globalStyle';
import Routes from './routes';
import store from './store';

export default function App() {
  return (
    <Theme>
      <GlobalStyle />
      <Provider store={store}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </Provider>
    </Theme>
  )
};