
export const DENTAL_SERVICES = [
  'Restauração',
  'Tratamento Infantil',
  'Canais',
  'Clareamento Caseiro',
  'Limpeza',
  'Raio X',
]

export const PJ = [
  'Urgência e Emergência',
  'Clínica Geral',
  'Cirurgia Oral Menor',
  'Tratamento de Gengivas',
  'Próteses',
  ...DENTAL_SERVICES,
]

export const PF = [ 
  ...PJ
]

export const ORTHO = ['Ortodontia Completa', ]