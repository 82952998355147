import Password from "./Password"

export default class PasswordChange extends Password {
    constructor({
        password,
        passwordConfirmation,
        recoverPasswordCode
    }) {
        super({password, passwordConfirm: passwordConfirmation})
        this.recoverPasswordCode = recoverPasswordCode
        this.passwordConfirmation = passwordConfirmation
    }
  }