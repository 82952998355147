import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import Layout from '../components/signup/layout'

export default function CreateRoute ({
  component: Component,
  render,
  isPrivate,
  isSignup,
  signed,
  step,
  maxSteps,
  title,
  hideHeader,
  noMargin,
  path,
  ...rest
}) {

  if (!signed && isPrivate && !isSignup && path !== '/trocar-senha') {
    return <Redirect to='/login' />
  }

  if (signed && !isPrivate && !isSignup && path !== '/trocar-senha') {
    return <Redirect to='/dashboard' />
  }
  return (
    <Route
      {...rest}
      path={path}
      render={
        render
          ? render
          : props =>
              isSignup ? (
                <Layout
                  isSignup
                  step={step}
                  maxSteps={maxSteps}
                  title={title}
                  hideHeader={hideHeader}
                  noMargin={noMargin}
                >
                  <Component {...props} />
                </Layout>
              ) : (
                <Component {...props} />
              )
      }
    />
  )
}

CreateRoute.propTypes = {
  isPrivate: PropTypes.bool,
  signed: PropTypes.bool,
  isSignup: PropTypes.bool,
  hideHeader: PropTypes.bool,
  noMargin: PropTypes.bool,
  step: PropTypes.number,
  title: PropTypes.string,
  render: PropTypes.func,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
}

CreateRoute.defaultProps = {
  isPrivate: false,
  signed: false,
  isSignup: false,
  noMargin: false,
  hideHeader: false,
  render: undefined,
  step: 1,
  title: '',
  component: undefined
}
