import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { makeStyles } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { ReactSVG } from 'react-svg'
import bronze from '../../assets/svgs/bronze.svg'
import gold from '../../assets/svgs/gold.svg'
import silver from '../../assets/svgs/silver.svg'
import * as Styled from './style'
import Layout from '../../components/Layout'
import MyNumbersSection from '../../components/MyNumbersSection'
import PlansSection from '../../components/PlansSection'
import ImportExcelSection from '../../components/ImportExcelSection'
import SupportMaterialSection from '../../components/SupportMaterialSection'
import HelpCentralSection from '../../components/HelpCentralSection'
import { get as getMe } from '../../shared/services/me'
import { getPermanentLink } from '../../shared/services/linkSelfService'
import {
  getComission,
  getModules,
} from '../../shared/services/broker'
import { get } from 'lodash'

const useStyle = makeStyles(theme => ({
  note: {
    color: '#505050',
    fontSize: 12,
    marginTop: 64,
    '@media(max-width: 767px)': {
      padding: '0 10%',
      textAlign: 'center',
    }
  },
  subtitle: {
    color: '#505050',
    fontSize: 18,
    marginTop: 14,
    display: `flex`,
    '@media(max-width: 767px)': {
      fontSize: 10,
      marginTop: 2,
    }
  },
  lplink: {
    fontSize: '1rem',
    textDecoration: 'underline',
    color: '#0091FF',
  }
}))

const TROPHY = {
  bronze: bronze,
  gold: gold,
  silver: silver,
}

export default function DashboardPage() {
  const dispatch = useDispatch()
  const user = useSelector(state => state.userReducer.user)
  const classes = useStyle()
  const [commission, setCommission] = useState({ currentWarriorLevel: '', commission: '' })
  const subtitle = useMemo(() => `${commission.currentWarriorLevel} - ${commission.commission}% de comissão`, [commission])
  const trophy = useMemo(() => TROPHY[(commission.currentWarriorLevel || '').split(' ')[0].toLowerCase() || bronze], [commission])
  const hasSaleModule = useMemo(() => (get(user, 'modules', []).find((sellerModule) => sellerModule === 'sale')), [user])
  const hasDashboardModule = useMemo(() => (get(user, 'modules', []).find((sellerModule) => sellerModule === 'dashboard')), [user])
  const updateUserData = useCallback((newData) => (
    dispatch({ type: 'USER_DATA', data: { ...user, ...newData } })
  ), [])
  useEffect(() => {
    getComission().then(apiComission => {
      setCommission(apiComission)
    })
    getModules().then(apiModules => {
      updateUserData({modules: apiModules.allowedModules})
    })
    getMe().then(me => {
      updateUserData(me)
    })
    if(!user.link) {
      getPermanentLink().then(({link}) => {
        updateUserData({link})
      })
    }
  }, [dispatch])

  return (
    <Layout
      Title={
        <>
          {' '}
          Olá, <strong> {user.name} </strong>{' '}
          {hasDashboardModule && commission.currentWarriorLevel && <article className={classes.subtitle}> <ReactSVG src={trophy || bronze} style={{ marginRight: 10 }} /> {subtitle}</article> }
          <p className={classes.lplink}> <a href={user.link} rel='noopener noreferrer' target='_blank'>Link da sua LP Empresa</a> </p>
        </>
      }
    >
      <Styled.Main>
        {hasDashboardModule && commission.currentWarriorLevel && <MyNumbersSection commission={commission} />}
        {hasSaleModule && <PlansSection />}
        <ImportExcelSection />
        <SupportMaterialSection />
        <HelpCentralSection />
        <article className={classes.note}>
          Não há qualquer relação empregatícia entre as partes, sendo certo que
          o VENDEDOR CADASTRADO é profissional autônomo, não se aplicando
          qualquer dispositivo da Consolidação das Leis do Trabalho, ser
          entendidas como um contrato de trabalho entre as partes, sendo certo
          que o VENDEDOR CADASTRADO não poderá ser considerado empregado ou
          preposto da CONTRATANTE. O VENDEDOR CADASTRADO agirá sempre com
          completa e total autonomia no exercício de suas funções, ficando, dado
          o caráter autônomo da sua atividade, sob sua inteira responsabilidade,
          não só os investimentos, quais sejam, locomoção, hospedagem, condução
          de mostruário, promoção cooperada de mercadorias objeto da
          representação, telex, telefone.
        </article>
      </Styled.Main>
    </Layout>
  )
}
