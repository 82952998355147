import React, { memo } from 'react';
import * as Styled from './header.style';
import Paragraph from '../Paragraph'


const Header = ({ children }) => {
  return (
    <Styled.Header>
      <Paragraph>
        {children}
    </Paragraph>
    </Styled.Header>
  )
}

export default memo(Header)