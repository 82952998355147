import styled, { css } from 'styled-components'
import { Container } from '@material-ui/core'

export const FormSection = styled(Container)`
  @media screen and (max-width: 599px) {
    padding: 12px 0;

    .not-focused {
      visibility: hidden;
      height: 0;
      width: 0;
      margin: 0;
      padding: 0 !important;
      overflow: hidden;
    }
  }
`

export const Title = styled.h2`
  color: #707070;
  font-size: 1rem;
  padding: 12px 22px 19px;
  font-weight: 400;
  @media(max-width: 767px) {
    margin-top: 20px;
  }
`

export const Rules = styled.section`
  text-align: left; 
  max-width: 200px;
  color: #4CAF4F;
  display: flex;
  margin-bottom: 8px;
  ${({ isInvalid }) => isInvalid && css`filter: grayscale(1);`}
`
