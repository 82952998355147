import React, { memo, useEffect, useState, useCallback } from 'react'
import Item from './Item'
import Section from '../Section'
import { useTheme, useMediaQuery, makeStyles } from '@material-ui/core'
import { getDashboard, getComission } from '../../shared/services/broker'
import ReplayIcon from '@material-ui/icons/Replay'
import { debounce } from 'lodash'
import Alert from '../Alert'
import { get } from 'lodash'

const generateItems = ({ numberOfLives, ammountSold, numberOfCarts }) => ([
  {
    icon: 'heart',
    title: 'Total de Vidas',
    value: numberOfLives
  },
  {
    icon: 'totalSold',
    title: 'Total de Vendas',
    value: numberOfCarts
  },
  {
    icon: 'totalGain',
    title: 'Total de Ganhos',
    value: ammountSold
  }
])

const useStyles = makeStyles(theme => ({
  rotateAnimation: {
    animation: `$rotation .5s ${theme.transitions.easing.easeInOut} infinite`
  },
  '@keyframes rotation': {
    '0%': {
      transform: 'rotate(-90deg)'
    },
    '100%': {
      transform: 'rotate(-360deg)'
    }
  }
}))

const MyNumbersSection = ({ children }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const [items, setItems] = useState(() => generateItems({}))
  const [loading, setLoading] = useState(false)
  const classes = useStyles()

  const [snackbarErrorOpened, setSnackbarErrorOpened] = useState(false)
  const [ammountSold, setAmmountSold] = useState(0)
  const [commission, setCommision] = useState(null)
  const [snackbarErrorMessage, setSnackbarErrorMessage] = useState('')

  useEffect(() => {
    if (commission) {
      setItems(() =>
        generateItems({
          ammountSold,
          numberOfCarts: commission.numberOfCarts,
          numberOfLives: commission.numberOfLives
        })
      )
    }
  }, [ammountSold, commission])

  const updateDashboard = useCallback(
    debounce(
      () => {
        setLoading(true)
        Promise.all([getDashboard(), getComission()])
          .then(([{ amountSold }, commissionResponse] ) => {
            setCommision(commissionResponse)
            setAmmountSold(
              get(amountSold, 'totals[0].value', 0).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
              })
            )
          })
          .catch(e => {
            setSnackbarErrorMessage(
              get(
                e,
                'messages[0].text',
                'Ocorreu um erro! Favor tente mais tarde!'
              )
            )
            setSnackbarErrorOpened(true)
          })
          .then(() => {
            setLoading(false)
          })
      },
      500,
      { leading: true }
    ),
    [setItems]
  )

  useEffect(() => {
    updateDashboard()
  }, [updateDashboard])

  return (
    <Section
      isFirst={true}
      title={
        <>
          {' '}
          Meus números{' '}
          <ReplayIcon
            style={{ cursor: 'pointer' }}
            className={loading ? classes.rotateAnimation : undefined}
            onClick={updateDashboard}
          />{' '}
        </>
      }
    >
      <section
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: isMobile ? 'center' : 'space-evenly',
          width: '90%'
        }}
      >
        {items.map((item, i) => (
          <Item
            {...item}
            isDifferentOnMobile={i !== items.length - 1}
            key={item.icon}
          />
        ))}
      </section>

      <Alert openState={snackbarErrorOpened} severity='error'>
        {snackbarErrorMessage}
      </Alert>
    </Section>
  )
}

export default memo(MyNumbersSection)
