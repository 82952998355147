import React from 'react'
import { Formik, Form } from 'formik'
import { Grid, Button } from '@material-ui/core'
import FormField from '../FormField'
import validationSchema from './validationSchema'
import DocumentTextField from '../DocumentTextField'
import { recover } from '../../shared/services/login'
import { get } from 'lodash'

export const ForgetPasswordForm = ({ setIsLoginSection, setSnackbarOpened }) => {

  function handleSubmit (body, actions) {
    if (actions.validateForm()) {
      recover(body)
        .then(result => {
          setSnackbarOpened(true)
          setIsLoginSection(true)
        })
        .catch(e => {
          const { messages } = e
          actions.setFieldError(
            get(messages, '[0].property.toLowerCase', () => '')(),
            get(messages, '[0].text', ''),
          )
        })
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ identification: '' }}
      validationSchema={validationSchema}
      validationOnChange={false}
    >
      {({ values, ...formikProps }) => (
        <Form style={{ width: '100%' }}>
          <Grid container spacing={2} direction='column'>
            <Grid item xs={12}>
              <FormField
                fullWidth
                variant='outlined'
                label='Seu CPF/CNPJ'
                name='identification'
                component={DocumentTextField}
              />
            </Grid>
            <Grid item xs={12} align={'center'}>
              <Button
                style={{
                  height: 40,
                  borderRadius: 50,
                  backgroundColor: '#121a3d'
                }}
                fullWidth
                type='submit'
                color='primary'
                variant='contained'
                aria-label='enviar'
                className='padding'
              >
                Reenviar Senha
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
